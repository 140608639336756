import { skipToken } from '@reduxjs/toolkit/query';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetUserProfileQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useGetCurrentUserProfile = () => {
  const { authStatus, userId } = useUserAuth();

  const skip = !userId || authStatus !== AuthStatus.LoggedIn;
  const getUserProfileQuery = useGetUserProfileQuery(skip ? skipToken : userId);

  return getUserProfileQuery?.data?.uuid !== userId ? null : getUserProfileQuery;
};
