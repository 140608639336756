import { Box, Typography } from '@mui/material';
import { appColors } from 'src/theme';

import { Logo } from '../AppLogo';

export const AppFooter = (): React.JSX.Element => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: { xs: 'center', sm: 'flex-end' },
      mb: { xs: 1, sm: 3 },
      mt: 1,
      px: 3,
    }}
  >
    <Typography
      noWrap
      sx={{
        color: 'secondary.main',
        fontSize: 10,
        fontWeight: 300,
        letterSpacing: 1,
        mr: 1,
      }}
    >
      POWERED BY
    </Typography>
    <Logo size={36} />
    <Typography
      noWrap
      sx={{
        color: appColors.chain,
        fontFamily: 'Poppins',
        fontWeight: 600,
        ml: 1,
        mt: '-2px',
      }}
      variant="body1"
    >
      agriplace
    </Typography>
  </Box>
);
