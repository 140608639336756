import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { LoadingButton } from '@mui/lab';
import { IconButton, Menu, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { type KeyboardEvent, useState } from 'react';
import { type AssessmentDetailOutput, CountryEnum } from 'src/__generated__/InternalApiTypes';
import { useGetCurrentUserProfile, useMutationFeedback, useTenantId } from 'src/hooks';
import {
  assessmentKebabMenuStrings,
  commonStrings,
  formikDialogStrings,
} from 'src/languages/en-UK';
import {
  useCreateCbAssessmentMetadataMutation,
  useUpdateCbAssessmentMetadataMutation,
} from 'src/services/farmApi';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

import { FormikCountryField } from '../formikcomponents/FormikCountryField';
import { FormikTextField } from '../formikcomponents/FormikTextField';

export interface AssessmentKebabMenuProps {
  assessmentDetail: AssessmentDetailOutput | undefined;
}

export const AssessmentKebabMenu = ({
  assessmentDetail,
}: AssessmentKebabMenuProps): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onCloseMenu = () => {
    setAnchorEl(null);
  };
  const { data: userProfile } = useGetCurrentUserProfile() ?? {};
  const isAssessmentAuthor = assessmentDetail?.author_id === userProfile?.uuid;
  const isDisabled = !isAssessmentAuthor;
  const tid = useTenantId();
  const cbAssessmentMetadata = assessmentDetail?.metadata;
  const [updateCbAssessmentMetadata, updateCbAssessmentMetadataResult] =
    useUpdateCbAssessmentMetadataMutation();
  const [createCbAssessmentMetadata, createCbAssessmentMetadataResult] =
    useCreateCbAssessmentMetadataMutation();

  const { isLoading: isUpdateCbAssessmentMetadataLoading } = updateCbAssessmentMetadataResult;
  const { isLoading: isCreateCbAssessmentMetadataLoading } = createCbAssessmentMetadataResult;

  const isLoading = isUpdateCbAssessmentMetadataLoading || isCreateCbAssessmentMetadataLoading;

  useMutationFeedback({
    result: createCbAssessmentMetadataResult,
    onSuccess: () => {
      onCloseMenu();
    },
    successMessage: formikDialogStrings('updateFormSuccess', {
      name: assessmentKebabMenuStrings('farmOrganizationDetails'),
    }),
    errorMessage: formikDialogStrings('updateFormFail', {
      name: assessmentKebabMenuStrings('farmOrganizationDetails'),
    }),
  });

  useMutationFeedback({
    result: updateCbAssessmentMetadataResult,
    onSuccess: () => {
      onCloseMenu();
    },
    successMessage: formikDialogStrings('updateFormSuccess', {
      name: assessmentKebabMenuStrings('farmOrganizationDetails'),
    }),
    errorMessage: formikDialogStrings('updateFormFail', {
      name: assessmentKebabMenuStrings('farmOrganizationDetails'),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      farmOrganizationName: cbAssessmentMetadata?.farm_name ?? '',
      address: cbAssessmentMetadata?.farm_address ?? '',
      city: cbAssessmentMetadata?.farm_city ?? '',
      postalCode: cbAssessmentMetadata?.farm_postal_code ?? '',
      country: cbAssessmentMetadata?.farm_country ?? '',
    },
    validationSchema: Yup.object().shape({
      farmOrganizationName: Yup.string().required(commonStrings('required')),
      address: Yup.string().required(commonStrings('required')),
      city: Yup.string().required(commonStrings('required')),
      postalCode: Yup.string().required(commonStrings('required')),
      country: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: (values) => {
      if (tid && cbAssessmentMetadata?.uuid) {
        updateCbAssessmentMetadata({
          tid,
          id: cbAssessmentMetadata.uuid,
          body: {
            farm_name: values?.farmOrganizationName,
            farm_address: values?.address,
            farm_city: values?.city,
            farm_postal_code: values?.postalCode,
            farm_country: CountryEnum[values?.country as keyof typeof CountryEnum],
          },
        });
      } else if (tid && assessmentDetail?.uuid) {
        createCbAssessmentMetadata({
          tid,
          body: {
            assessment_id: assessmentDetail.uuid,
            farm_name: values?.farmOrganizationName,
            farm_address: values?.address,
            farm_city: values?.city,
            farm_postal_code: values?.postalCode,
            farm_country: CountryEnum[values?.country as keyof typeof CountryEnum],
          },
        });
      }
    },
  });

  const onKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    evt.stopPropagation();
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        sx={(theme) => (open ? { backgroundColor: theme.palette.action.focus } : {})}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={onCloseMenu}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={2}
            sx={{
              width: '500px',
              px: 2,
              py: 1,
            }}
          >
            <Typography variant="subtitle2">
              {assessmentKebabMenuStrings('farmOrganizationDetails')}
            </Typography>
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="farmOrganizationName"
              label={commonStrings('farmOrganizationName')}
              onKeyDown={onKeyDown}
              required
              size="small"
            />
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="address"
              label={commonStrings('address')}
              onKeyDown={onKeyDown}
              required
              size="small"
            />
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="city"
              label={commonStrings('city')}
              onKeyDown={onKeyDown}
              required
              size="small"
            />
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="postalCode"
              label={commonStrings('postalCode')}
              onKeyDown={onKeyDown}
              required
              size="small"
            />
            <FormikCountryField
              disabled={isDisabled}
              formik={formik}
              id="country"
              label={commonStrings('country')}
              required
              size="small"
            />
            {isAssessmentAuthor && (
              <Stack
                sx={{
                  alignItems: 'center',
                }}
              >
                <LoadingButton
                  disabled={!canSubmitFormik(formik, false)}
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  {commonStrings('saveChanges')}
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </form>
      </Menu>
    </>
  );
};
