import { Auth, type CognitoUser } from '@aws-amplify/auth';
import { AuthStatus } from 'src/enums/AuthStatus';
import { commonStrings } from 'src/languages/en-UK';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

export interface UserData {
  userId: string;
  user: CognitoUser;
  authStatus: AuthStatus;
}

export enum SignUpOriginEnum {
  AGRIPLACE = 'agriplace',
  SMK = 'smk',
  LEAF = 'leaf',
}

const authEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    forgotPassword: build.mutation<{ isSuccess: boolean }, { email: string }>({
      async queryFn({ email }) {
        try {
          await Auth.forgotPassword(email.toLowerCase());
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    resetPassword: build.mutation<
      { isSuccess: boolean },
      { email: string; code: string; password: string }
    >({
      async queryFn({ email, code, password }) {
        try {
          await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    changePassword: build.mutation<
      { isSuccess: boolean },
      { passwordOld: string; passwordNew: string }
    >({
      async queryFn({ passwordOld, passwordNew }) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, passwordOld, passwordNew);
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    changeTemporaryPassword: build.mutation<
      { isSuccess: boolean },
      { user: CognitoUser; passwordNew: string; fullName: string; language: string }
    >({
      async queryFn({ fullName, language, passwordNew, user }) {
        try {
          await Auth.completeNewPassword(user, passwordNew, {
            name: fullName,
            locale: language,
          });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signUp: build.mutation<
      UserData,
      {
        email: string;
        password: string;
        fullName: string;
        language: string;
        accountType: string;
        origin?: string;
      }
    >({
      async queryFn({
        email,
        password,
        fullName,
        language,
        accountType,
        origin = SignUpOriginEnum.AGRIPLACE,
      }) {
        let signUpOrigin = origin;
        if (!Object.values(SignUpOriginEnum).includes(origin as SignUpOriginEnum)) {
          signUpOrigin = SignUpOriginEnum.AGRIPLACE;
        }
        try {
          const { user, userSub: userId } = await Auth.signUp({
            password,
            username: email.toLowerCase(),
            attributes: {
              name: fullName,
              locale: language,
              'custom:source_app': 'farm',
              'custom:origin': signUpOrigin,
              'custom:account_type': accountType,

              // TODOHasan: Talk to Jack for these params should be determined programmatically or not
              'custom:privacyPolicyVer': '1.1',
              'custom:termsOfServiceVer': '1.1',
            },
          });
          return { data: { user, userId, authStatus: AuthStatus.LoggedOut } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    verifySignUp: build.mutation<{ isSuccess: boolean }, { email: string; code: string }>({
      async queryFn({ email, code }) {
        try {
          await Auth.confirmSignUp(email.toLowerCase(), code);
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    resendSignUpVerification: build.mutation<{ isSuccess: boolean }, { email: string }>({
      async queryFn({ email }) {
        try {
          await Auth.resendSignUp(email.toLowerCase());
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signIn: build.mutation<UserData, { email: string; password: string }>({
      async queryFn({ email, password }) {
        try {
          const user = await Auth.signIn(email.toLowerCase(), password);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { userAttributes } = user.challengeParam || {};
            return {
              data: {
                user,
                userAttributes,
                userId: user.username,
                authStatus: AuthStatus.PasswordRequired,
              },
            };
          }
          return { data: { user, userId: user.attributes.sub, authStatus: AuthStatus.LoggedIn } };
        } catch (error) {
          let message = '';
          if (error.code === 'UserNotConfirmedException') {
            message = commonStrings('checkYourMailToConfirm');
          } else {
            message = error?.message || commonStrings('unknownErrorOccurred');
          }
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signOut: build.mutation<UserData, void>({
      // @ts-expect-error Third party issue
      async queryFn() {
        try {
          await Auth.signOut();
          return { data: { user: null, userId: null, authStatus: AuthStatus.LoggedOut } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    getCurrentAuthenticatedUser: build.query<UserData, null>({
      // @ts-expect-error Third party issue
      async queryFn() {
        try {
          const user = await Auth.currentAuthenticatedUser();
          return { data: { user, userId: user.attributes.sub, authStatus: AuthStatus.LoggedIn } };
        } catch (error) {
          return { data: { user: null, userId: null, authStatus: AuthStatus.LoggedOut } };
        }
      },
      providesTags: [TagType.Auth],
    }),
  }),
  overrideExisting: false,
});

export const {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useChangeTemporaryPasswordMutation,
  useVerifySignUpMutation,
  useResendSignUpVerificationMutation,
  useSignUpMutation,
  useSignInMutation,
  useSignOutMutation,
  useGetCurrentAuthenticatedUserQuery,
} = authEndpoints;
