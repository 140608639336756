import { type PayloadAction } from '@reduxjs/toolkit';
import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';
import { type TransformedOrganizationCultivationListOutputItem } from 'src/services/farmApi';

import { type AssessmentConfigState } from '../types';
import { buildRowsColumns } from '../utils/buildRowsColumns';

export interface SetLayoutPayload {
  cultivationList: TransformedOrganizationCultivationListOutputItem[];
  moduleList: ModuleListOutputItem[] | undefined;
  hasPlotName: boolean;
}

/**
 *
 * @param state -
 * @param action -
 * @returns
 */
export const setLayout = (
  state: AssessmentConfigState,
  action: PayloadAction<SetLayoutPayload>,
): AssessmentConfigState => ({
  ...state,
  ...buildRowsColumns(
    action.payload.cultivationList,
    action.payload.moduleList,
    action.payload.hasPlotName,
  ),
});
