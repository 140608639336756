import { IconButton, Tooltip, type TooltipProps, useMediaQuery } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useContext, useState } from 'react';
import { ThemeContext } from 'src/components/ThemeProvider';

export const MobileCompatibleTooltip = ({ children, title, ...restProps }: TooltipProps) => {
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showMobileTooltip, setShowMobileTooltip] = useState(false);

  const handleTooltipClose = () => {
    setShowMobileTooltip(false);
  };

  const handleTooltipOpen = () => {
    setShowMobileTooltip(true);
  };

  return isMobile ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        {...restProps}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleTooltipClose}
        open={showMobileTooltip}
        title={title}
      >
        <IconButton
          onClick={handleTooltipOpen}
          sx={{ p: 0 }}
        >
          {children}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Tooltip
      {...restProps}
      arrow
      title={title}
    >
      {children}
    </Tooltip>
  );
};
