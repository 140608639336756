import { CheckRounded, ContentCopyRounded } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useAppDispatch } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { openSnackbar } from 'src/store/snackbarSlice';

export interface CopyToClipboardTextProps {
  urlToShare: string;
  isCopied?: boolean;
}

export const CopyToClipboardText = ({ urlToShare, isCopied = false }: CopyToClipboardTextProps) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const [hasCopiedUrl, setHasCopiedUrl] = useState(isCopied);

  useEffect(() => {
    setHasCopiedUrl(isCopied);
  }, [isCopied]);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlToShare);
    setHasCopiedUrl(true);
    dispatch(
      openSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
        message: commonStrings('linkWasCopied'),
        severity: 'success',
      }),
    );
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <TextField
        disabled
        fullWidth
        id="outlined-url"
        multiline
        sx={{
          pr: 1,
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.primary,
          },
        }}
        value={urlToShare}
        variant="outlined"
      />
      <IconButton
        aria-haspopup="true"
        aria-label="copy shareable link"
        id="copy-button"
        onClick={handleCopyUrl}
      >
        {hasCopiedUrl ? <CheckRounded color="success" /> : <ContentCopyRounded />}
      </IconButton>
    </Box>
  );
};
