import { LogoutRounded, PersonRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { cloneElement, type MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useGetCurrentUserProfile, useRoleCheck } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { farmApi, useSignOutMutation } from 'src/services/farmApi';
import { ROUTE_PATH_ORGANIZATION } from 'src/settings';

import { type RouteDefinition } from '../types/RouteDefinition';

interface AppAccountMenuProps {
  routes: RouteDefinition[];
}

export const AppAccountMenu = ({ routes }: AppAccountMenuProps): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [signOut] = useSignOutMutation();
  const navigate = useNavigate();
  const { isAuditor } = useRoleCheck();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchor);

  const { data: userProfile } = useGetCurrentUserProfile() ?? {};

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const handleLogOut = async () => {
    localStorage.removeItem('storedTenantId');
    localStorage.setItem('hasRedirect', 'true');
    await signOut();
    dispatch(farmApi.util.resetApiState());
  };

  // Prevent re-renders by currying navigation handler
  const routesWithNavigation = routes.map((route) => ({
    ...route,
    handleNavigate: () => {
      if (isOpen) {
        closeMenu();
      }
      navigate(route.path);
    },
  }));

  // Don't render component at all if user data is missing
  if (!userProfile) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={openMenu}
        sx={{ py: 0.75, borderRadius: '6px', border: 1, borderWidth: 1, borderColor: 'divider' }}
      >
        <PersonRounded color="primary" />
        <Typography
          sx={{
            color: 'primary.main',
            pl: 1,
          }}
          variant="body1"
        >
          {userProfile.full_name}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        onClose={closeMenu}
        open={isOpen}
        sx={{ mt: 1 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            mb: 1.75,
            mt: 0.75,
            mx: 2,
            whiteSpace: 'nowrap',
            width: 'min-content',
          }}
        >
          <Typography variant="body2">{userProfile.email}</Typography>
        </Box>
        <MenuList>
          {routesWithNavigation.map((route) => {
            if (isAuditor && route.path === ROUTE_PATH_ORGANIZATION) {
              return null;
            }

            return (
              <MenuItem
                key={route.path}
                onClick={route.handleNavigate}
              >
                {route.icon && <ListItemIcon>{cloneElement(route.icon)}</ListItemIcon>}
                <ListItemText>{route.name}</ListItemText>
              </MenuItem>
            );
          })}
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <LogoutRounded />
            </ListItemIcon>
            <ListItemText>{commonStrings('logOut')}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
