import { skipToken } from '@reduxjs/toolkit/query';
import { useGetUserProfileQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useCurrentUser = (additionalSkip = false) => {
  const { userId } = useUserAuth();
  const skip = !userId || additionalSkip;
  const {
    data: userProfileData,
    isLoading: isUserProfileLoading,
    isError: isUserProfileError,
    isSuccess: isUserProfileSuccess,
    ...rest
  } = useGetUserProfileQuery(skip ? skipToken : userId);

  return {
    ...userProfileData,
    isUserProfileLoading,
    isUserProfileError,
    isUserProfileSuccess,
    ...rest,
  };
};
