import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useTenantId } from 'src/hooks';
import { useGetAssessmentControlPointBreadcrumbsListQuery } from 'src/services/farmApi';
import { type Breadcrumb } from 'src/services/farmApi';

export const useControlPointBreadcrumbs = (
  controlPointId: string,
): [Breadcrumb | undefined, boolean] => {
  const { id: assessmentId } = useParams();
  const tid = useTenantId();
  const skip = !tid || !assessmentId;
  const { data: controlPointBreadcrumbsData, isLoading: isControlPointBreadcrumbsDataLoading } =
    useGetAssessmentControlPointBreadcrumbsListQuery(
      skip
        ? skipToken
        : {
            tid,
            id: assessmentId,
          },
    );

  const controlPointBreadcrumb = controlPointBreadcrumbsData?.[controlPointId];

  return [controlPointBreadcrumb, isControlPointBreadcrumbsDataLoading];
};
