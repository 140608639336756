import { createContext, type ReactNode, useEffect, useMemo, useState } from 'react';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetCurrentUserProfile, useUserAuth } from 'src/hooks';

export type AnyMembership = {
  tenant_id: string;
  roles: string[];
  organization_id?: string;
  organization_name?: string;
};

interface TenantContextData {
  tenantId: string | null;
  organizationId: string | null;
  activeMembership: AnyMembership | null | undefined;
  setActiveMembership: ((membership: AnyMembership) => void) | null;
  setStoredTenantId: ((tenantId: string) => void) | null;
}

export const TenantContext = createContext<TenantContextData>({
  tenantId: null,
  organizationId: null,
  activeMembership: null,
  setActiveMembership: null,
  setStoredTenantId: null,
});
TenantContext.displayName = 'TenantContext';

interface TenantProviderProps {
  children: ReactNode;
}

export const TenantProvider = ({ children }: TenantProviderProps): React.JSX.Element => {
  const { data: userProfile } = useGetCurrentUserProfile() ?? {};
  const { authStatus } = useUserAuth();
  const [activeMembership, setActiveMembership] = useState<AnyMembership | undefined | null>(null);
  const [storedTenantId, setStoredTenantId] = useState(localStorage.getItem('storedTenantId'));

  useEffect(() => {
    if (activeMembership?.tenant_id) {
      setStoredTenantId(activeMembership.tenant_id);
      localStorage.setItem('storedTenantId', activeMembership.tenant_id);
    } else {
      setActiveMembership(userProfile?.memberships?.[0]);
    }
  }, [activeMembership, userProfile?.memberships]);

  useEffect(() => {
    if (authStatus === AuthStatus.LoggedIn) {
      const membership = userProfile?.memberships?.find(
        ({ tenant_id }) => tenant_id === storedTenantId,
      );
      if (membership) {
        setActiveMembership(membership);
      }
    } else if (authStatus) {
      setActiveMembership(null);
      setStoredTenantId(null);
      localStorage.removeItem('storedTenantId');
    }
  }, [authStatus, storedTenantId, userProfile]);

  const memoizedTenant = useMemo(
    () => ({
      tenantId: activeMembership?.tenant_id ?? null,
      organizationId: activeMembership?.organization_id ?? null,
      activeMembership,
      setActiveMembership,
      setStoredTenantId,
    }),
    [activeMembership],
  );

  return <TenantContext.Provider value={memoizedTenant}>{children}</TenantContext.Provider>;
};
