import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';

export type SelectionListChangeHandler = (ids: string[], checked: boolean) => void;

type SelectionListResetHandler = (alternateResetValue?: (string | undefined)[]) => void;

type SelectionListStateReturnValue = [
  (string | undefined)[],
  SelectionListChangeHandler,
  SelectionListResetHandler,
  boolean,
];

/**
 * A hook to simplify adding and removing sub-sets of IDs from a master set of IDs.
 * @returns An array containing:
 *    - A list of the existing IDs (similar to useState)
 *    - A handler function for adding or removing IDs from that list
 */
export const useSelectionListState = (
  defaultState: (string | undefined)[],
  moduleList?: ModuleListOutputItem[],
  skipReset = false,
): SelectionListStateReturnValue => {
  const resetValue = defaultState || [];
  const [existingIds, setExistingIds] = useState<(string | undefined)[]>(resetValue);
  useEffect(() => {
    if (!skipReset) {
      setExistingIds(defaultState);
    }
  }, [defaultState, skipReset]);
  const changeHandler: SelectionListChangeHandler = (
    changedIds: string[],
    checked: boolean,
  ): void => {
    if (checked) {
      if (moduleList && changedIds?.length !== 0) {
        const standardId = moduleList.find((module) => module.uuid === changedIds[0])?.standard_id;
        if (standardId) {
          const parentId = moduleList.find(
            (module) => module.standard_id === standardId && !module.is_applicable_to_organization,
          )?.uuid;
          if (parentId && !changedIds.includes(parentId)) {
            changedIds.push(parentId);
          }
        }
      }
      setExistingIds([
        ...(existingIds || []),
        ...changedIds.filter((changedId) => !existingIds?.includes(changedId)),
      ]);
    } else {
      let childIds: (string | undefined)[] = [];
      if (moduleList && changedIds?.length !== 0) {
        const selectedColumn = moduleList.find((module) => module.uuid === changedIds[0]);
        if (selectedColumn?.standard_id && !selectedColumn?.is_applicable_to_organization) {
          childIds = moduleList
            .filter(
              (module) =>
                module.standard_id === selectedColumn?.standard_id &&
                module.is_applicable_to_organization,
            )
            .map((item) => item.uuid);
        }
      }
      setExistingIds([
        ...(existingIds || []).filter(
          (visibleId) =>
            visibleId && !changedIds.includes(visibleId) && !childIds.includes(visibleId),
        ),
      ]);
    }
  };
  const resetHandler: SelectionListResetHandler = (alternateResetValue?: string[]): void => {
    setExistingIds(alternateResetValue || resetValue);
  };
  const isDirty = !isEqual(resetValue, existingIds);
  return [existingIds, changeHandler, resetHandler, isDirty];
};
