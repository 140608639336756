import { AddRounded } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { type MembershipsDetail } from 'src/__generated__/InternalApiTypes';
import { type AnyMembership, TenantContext } from 'src/components/TenantProvider';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useCurrentOrganization, useGetCurrentUserProfile, useRoleCheck } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { appOrganizationSwitcherStrings } from 'src/languages/en-UK';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_CREATE_MORE_ORGANIZATION, ROUTE_PATH_DASHBOARD } from 'src/settings';
import { leafTheme, smkTheme, theme } from 'src/theme';

import { ButtonMenu, type MenuItemType } from '../ButtonMenu';

type AppOrganizationSwitcherProps = {
  inlineMenu?: boolean;
};
export const AppOrganizationSwitcher = ({
  inlineMenu = false,
}: AppOrganizationSwitcherProps): React.JSX.Element | null => {
  const origin = useGetUserOrigin();
  const navigate = useNavigate();
  const { data: userProfile } = useGetCurrentUserProfile() ?? {};
  const { activeMembership, setActiveMembership } = useContext(TenantContext);
  const currentOrganizationName = activeMembership?.organization_name;
  const { data: organization } = useCurrentOrganization();
  const { theme: currentTheme, changeTheme } = useContext(ThemeContext);
  const { isFarm } = useRoleCheck();
  useEffect(() => {
    if (origin && changeTheme) {
      if (currentTheme !== smkTheme && origin === SignUpOriginEnum.SMK) {
        changeTheme(smkTheme);
      } else if (currentTheme !== leafTheme && origin === SignUpOriginEnum.LEAF) {
        changeTheme(leafTheme);
      } else if (currentTheme !== theme && origin === SignUpOriginEnum.AGRIPLACE) {
        changeTheme(theme);
      }
    }
  }, [changeTheme, currentTheme, origin]);

  const handleChangeOrganization = (membership: AnyMembership) => {
    setActiveMembership?.(membership);
    navigate(ROUTE_PATH_DASHBOARD);
  };

  const organizationsForMenu: MenuItemType[] = userProfile?.memberships
    ? userProfile.memberships.map((membership: MembershipsDetail) => ({
        label: membership.organization_name,
        onClick: () => handleChangeOrganization(membership),
      }))
    : [];

  if (isFarm) {
    organizationsForMenu.push({
      dense: true,
      icon: <AddRounded />,
      label: appOrganizationSwitcherStrings('addNewFarm'),
      onClick: () => {
        navigate(ROUTE_PATH_CREATE_MORE_ORGANIZATION, {
          state: {
            shouldResetOrganizationDetailForm: true,
          },
        });
      },
    });
  }

  if (!userProfile?.memberships || !currentOrganizationName) {
    return null;
  }

  const hasLogo = organization?.logo?.file_object;
  const initials = currentOrganizationName?.replace(/[^A-Z]+/g, '') || '';

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: inlineMenu ? 'flex' : { xs: 'none', lg: 'flex' },
        gap: 1,
      }}
    >
      <Avatar
        src={organization?.logo?.file_object}
        sx={{
          width: 44,
          height: 44,
          bgcolor: hasLogo ? 'transparent' : 'primary.main',
          fontSize: 26 - (initials.length - 1) * 4,
        }}
      >
        {!hasLogo && initials}
      </Avatar>
      {organizationsForMenu?.length > 1 || isFarm ? (
        <ButtonMenu
          data-cy="app-organization-switcher"
          menuItems={organizationsForMenu}
        >
          {currentOrganizationName}
        </ButtonMenu>
      ) : (
        <Typography
          sx={{
            color: 'primary.main',
          }}
          variant="body1"
        >
          {currentOrganizationName}
        </Typography>
      )}
    </Box>
  );
};
