import { type StandardLogoListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';

const RESOURCE = 'standard-logos';

const standardLogosEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStandardLogoList: build.query<StandardLogoListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetStandardLogoListQuery } = standardLogosEndpoints;
