import { t } from '@transifex/native';
import { T } from '@transifex/react';

import {
  type AccountDetailsFormStringsKeyType,
  type AccountInvitationTableStringsKeyType,
  type AccountNotificationsFormStringsKeyType,
  type AccountPageStringsKeyType,
  type AppLogoStringsKeyType,
  type AppOrganizationSwitcherStringsKeyType,
  type AppPrimaryNavigationStringsKeyType,
  type ApprovedOutgoingRequestDialogStringsKeyType,
  type AssessmentActionBarButtonsStringsKeyType,
  type AssessmentArchivePageStringsKeyType,
  type AssessmentAuditorEvidenceStepStringsKeyType,
  type AssessmentAutoAttachRequestDialogStringsKeyType,
  type AssessmentConfigActionBarStringsKeyType,
  type AssessmentConfigDialogStringsKeyType,
  type AssessmentConfigMatrixStringsKeyType,
  type AssessmentConfigStepStringsKeyType,
  type AssessmentControlPointCategoriesDrawerStringsKeyType,
  type AssessmentControlPointDrawerToggleStringsKeyType,
  type AssessmentControlPointModulesDrawerStringsKeyType,
  type AssessmentControlPointStepProgressDialogStringsKeyType,
  type AssessmentControlPointStepStringsKeyType,
  type AssessmentCreateDialogStringsKeyType,
  type AssessmentEvidenceCategoryStringsKeyType,
  type AssessmentEvidenceStepStringsKeyType,
  type AssessmentExportResultDialogStringsKeyType,
  type AssessmentFinishDialogForAuditorsStringsKeyType,
  type AssessmentFinishDialogForCBManagersStringsKeyType,
  type AssessmentFinishDialogForFarmManagersStringsKeyType,
  type AssessmentFinishDialogForReviewersStringsKeyType,
  type AssessmentKebabMenuStringsKeyType,
  type AssessmentPreliminaryQuestionsConfirmDialogStringsKeyType,
  type AssessmentPreliminaryQuestionsStepStringsKeyType,
  type AssessmentReportStepStringsKeyType,
  type AssessmentShareDialogStringsKeyType,
  type AssessmentShareWithAuditorDialogStringsKeyType,
  type AssessmentShareWithAuditorResultDialogStringsKeyType,
  type ChangePasswordFormStringsKeyType,
  type ChangeTemporaryPasswordPageStringsKeyType,
  type CheckboxCellStringsKeyType,
  type CommentStringsKeyType,
  type CommonStringsKeyType,
  type ControlPointAnsweredByTriggerChipStringsKeyType,
  type ControlPointAnswerPointsStringsKeyType,
  type ControlPointAnswerStringsKeyType,
  type ControlPointDropdownAnswerStringsKeyType,
  type ControlPointEvidenceDialogStringsKeyType,
  type ControlPointGuidanceDialogStringsKeyType,
  type ControlPointGuidanceTabStringsKeyType,
  type ControlPointHeaderKebabMenuStringsKeyType,
  type ControlPointHeaderStringsKeyType,
  type ControlPointStringsKeyType,
  type ControlPointUploadedEvidenceStringsKeyType,
  type CorrectiveActionStringsKeyType,
  type CreateEvidenceDialogStringsKeyType,
  type CreateEvidenceSaveDialogStringsKeyType,
  type DashboardBlockRowStringsKeyType,
  type DashboardOrganizationBlockStringsKeyType,
  type DashboardPageStringsKeyType,
  type DashboardRequestBlockStringsKeyType,
  type DashboardRequestPageStringsKeyType,
  type DashboardUserBlockStringsKeyType,
  type DocumentPreviewStringsKeyType,
  type EditCorrectiveActionDialogStringsKeyType,
  type EditNonConformityDialogStringsKeyType,
  type EvidenceAttachToggleStringsKeyType,
  type EvidenceControlPointTableStringsKeyType,
  type EvidenceDeleteDialogStringsKeyType,
  type EvidenceEditDialogStringsKeyType,
  type EvidenceExpiredDocumentsListStringsKeyType,
  type EvidenceFormsDialogStringsKeyType,
  type EvidenceReviewControlPointStringsKeyType,
  type EvidenceReviewDrawerStringsKeyType,
  type EvidenceTableStringsKeyType,
  type EvidenceTableUploadDialogStringsKeyType,
  type EvidenceTabsPageStringsKeyType,
  type FiltersStringsKeyType,
  type ForgotPasswordPageStringsKeyType,
  type FormikDialogStringsKeyType,
  type FormikFileUploadFieldStringsKeyType,
  type FormikInvitationRoleFieldStringsKeyType,
  type FormikRegisterAccountTypeFieldStringsKeyType,
  type GenericDeleteDialogStringsKeyType,
  type GetCultivationDisplayNameStringsKeyType,
  type HelpDeskMenuStringsKeyType,
  type HelpDocumentsDialogStringsKeyType,
  type JustificationTextFieldStringsKeyType,
  type LoginPageStringsKeyType,
  type MobileCompatibleDataGridStringsKeyType,
  type NewCommentFormStringsKeyType,
  type NewCorrectiveActionFormStringsKeyType,
  type NewNonConformityFormStringsKeyType,
  type NonConformityStringsKeyType,
  type OrganizationClientIdentifierFormLeafStringsKeyType,
  type OrganizationClientIdentifiersStringsKeyType,
  type OrganizationCultivationTableStringsKeyType,
  type OrganizationDetailsFormStringsKeyType,
  type OrganizationInvitationEditDialogStringsKeyType,
  type OrganizationInvitationTableStringsKeyType,
  type OrganizationPageStringsKeyType,
  type OrganizationUserTableStringsKeyType,
  type PendingIncomingRequestDialogStringsKeyType,
  type ResetPasswordPageStringsKeyType,
  type RoutesHeaderStringsKeyType,
  type SelectionListStringsKeyType,
  type SignUpFormStringsKeyType,
  type SignUpVerificationPageStringsKeyType,
  type SplashPageStringsKeyType,
  type StepperFinishDialogStringsKeyType,
  type StepperPageStringsKeyType,
  type TabbedPageStringsKeyType,
} from './languageType';

export const commonStrings = (
  key: CommonStringsKeyType,
  params?: {
    max?: number;
    min?: number;
    name?: string;
    organizationName?: string;
  },
): string => {
  switch (key) {
    case 'add':
      return t('Add', {
        _key: 'add',
        _context: 'NewCommentForm,NewNonConformityForm,NewCorrectiveActionForm,SplashPage',
      });
    case 'addCultivation':
      return t('Add cultivation', {
        _key: 'addCultivation',
        _context: 'AssessmentConfigDialog,OrganizationCultivationTable',
      });
    case 'addNewCultivation':
      return t('Add new cultivation', {
        _key: 'addNewCultivation',
        _context: 'AssessmentConfigDialog,OrganizationCultivationTable',
      });
    case 'addNonConformity':
      return t('Add non-conformity', {
        _key: 'addNonConformity',
        _context: 'ControlPointHeaderKebabMenu,NewNonConformityForm',
      });
    case 'address':
      return t('Address', {
        _key: 'address',
        _context:
          'OrganizationDetailsForm,AssessmentKebabMenu,AssessmentCreateDialog,AssessmentArchivePage',
      });
    case 'all':
      return t('All', {
        _key: 'all',
        _context: 'Filters,SelectionList',
      });
    case 'answersByCultivations':
      return t('ANSWERS BY CULTIVATIONS', {
        _key: 'answersByCultivations',
        _context: 'ControlPointAnswer,ControlPointDropdownAnswer,ControlPointDynamicAnswer',
      });
    case 'attach':
      return t('Attach', {
        _key: 'attach',
        _context: 'EvidenceAttachToggle,EvidenceControlPointTable',
      });
    case 'attached':
      return t('Attached', {
        _key: 'attached',
        _context: 'AssessmentAuditorEvidenceStep,EvidenceTable',
      });
    case 'attachedTo':
      return t('Attached to', {
        _key: 'attachedTo',
        _context: 'AssessmentAuditorEvidenceStep,EvidenceTable',
      });
    case 'attachToAll':
      return t('Attach to all', {
        _key: 'attachToAll',
        _context: 'EvidenceTableUploadDialog,EvidenceReviewDrawer',
      });
    case 'back':
      return t('Back', {
        _key: 'back',
        _context:
          'AssessmentActionBarButtons,AssessmentConfigDialog,OrganizationDetailsForm,TabbedPage',
      });
    case 'backToLogIn':
      return t('Back to log in', {
        _key: 'backToLogIn',
        _context: 'ForgotPasswordPage,SignUpVerificationPage',
      });
    case 'backendCreateFailMessage':
      return t('Failed to create {name}.', {
        _key: 'backendCreateFailMessage',
        _context: 'FormikDialog,OrganizationDetailsForm',
        ...params,
      });
    case 'backendCreateSuccessMessage':
      return t('Successfully created {name}.', {
        _key: 'backendCreateSuccessMessage',
        _context:
          'AssessmentCreateDialog,DashboardAssessmentsBlock,FormikDialog,OrganizationDetailsForm,AssessmentArchivePage',
        ...params,
      });
    case 'backendSaveFailMessage':
      return t('Failed to save changes to {name}.', {
        _key: 'backendSaveFailMessage',
        _context:
          'AccountNotificationsForm,AssessmentConfigDialog,AssessmentConfigStep,OrganizationDetailsForm',
        ...params,
      });
    case 'backendSaveSuccessMessage':
      return t('Saved changes to {name}.', {
        _key: 'backendSaveSuccessMessage',
        _context: 'AccountNotificationsForm,AssessmentConfigStep,OrganizationDetailsForm',
        ...params,
      });
    case 'backendDeleteFailMessage':
      return t('Failed to delete {name}.', {
        _key: 'backendDeleteFailMessage',
        _context: 'EvidenceDeleteDialog,GenericDeleteDialog',
        ...params,
      });
    case 'bulkAttachEvidenceFail':
      return t('Failed to attach evidence to all control points.', {
        _key: 'bulkAttachEvidenceFail',
        _context: 'EvidenceTableUploadDialog,CreateEvidenceSaveDialog,EvidenceReviewDrawer',
      });
    case 'bulkAttachEvidenceSuccess':
      return t('Successfully attached evidence to all control points.', {
        _key: 'bulkAttachEvidenceSuccess',
        _context: 'EvidenceTableUploadDialog,CreateEvidenceSaveDialog,EvidenceReviewDrawer',
      });
    case 'cancel':
      return t('Cancel', {
        _key: 'cancel',
        _context:
          'AssessmentActionBarButtons,AssessmentConfigDialog,AssessmentFinishDialogForCBManagers,ChangePasswordForm,EvidenceDeleteDialog',
      });
    case 'certificationCompany':
      return t('Certification company', {
        _key: 'certificationCompany',
        _context:
          'AssessmentConfigClientIdentifierForm,AssessmentShareWithAuditorDialog,OrganizationClientIdentifiers',
      });
    case 'change':
      return t('Change', {
        _key: 'change',
        _context: 'ChangePasswordForm,OrganizationDetailsForm',
      });
    case 'changePassword':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm,ChangePasswordDialog"
          _key="changePassword"
          _str="Change password"
        />
      );
    case 'checkYourMailToConfirm':
      return t('Please check your e-mail to confirm your account.', {
        _key: 'checkYourMailToConfirm',
        _context: 'SignUpForm,SignUpVerificationPage,auth',
      });
    case 'clientId':
      return t('Client ID', {
        _key: 'clientId',
        _context: 'AssessmentConfigClientIdentifierForm,OrganizationClientIdentifiers',
      });
    case 'city':
      return t('City', {
        _key: 'city',
        _context: 'OrganizationDetailsForm,AssessmentKebabMenu,AssessmentCreateDialog',
      });
    case 'country':
      return t('Country', {
        _key: 'country',
        _context: 'OrganizationDetailsForm,AssessmentKebabMenu,AssessmentCreateDialog',
      });
    case 'close':
      return t('Close', {
        _key: 'close',
        _context:
          'AssessmentCreateDialog,AssessmentShareDialog,AssessmentShareWithAuditorResultDialog,EvidencePreviewDialog,GenericDeleteDialog,HelpDocumentPreviewDialog',
      });
    case 'comments':
      return t('Comments', {
        _key: 'comments',
        _context: 'Filters,ControlPointComments',
      });
    case 'complete':
      return t('Complete', {
        _key: 'complete',
        _context:
          'AssessmentConfigDialog,EvidenceTableUploadDialog,AssessmentFinishDialogForFarmManagers,AssessmentReportStep,ControlPointEvidenceDialog,EvidenceReviewDrawer',
      });
    case 'compliant':
      return t('Compliant', {
        _key: 'compliant',
        _context: 'AssessmentFinishDialogForCBManagers,AssessmentArchivePage',
      });
    case 'confirm':
      return t('Confirm', {
        _key: 'confirm',
        _context:
          'AssessmentFinishDialogForCBManagers,AssessmentPreliminaryQuestionsConfirmDialog,AssessmentReportStep',
      });
    case 'copied':
      return t('Copied!', {
        _key: 'copied',
        _context: 'AssessmentFinishDialogForAuditors,AssessmentFinishDialogForReviewers',
      });
    case 'copyLink':
      return t('Copy the link', {
        _key: 'copyLink',
        _context: 'AssessmentShareDialog,AssessmentShareWithAuditorDialog',
      });
    case 'copyLinkToClipboard':
      return t('Copy link to clipboard', {
        _key: 'copyLinkToClipboard',
        _context: 'AssessmentFinishDialogForAuditors,AssessmentFinishDialogForReviewers',
      });
    case 'createAccount':
      return t('Create account', {
        _key: 'createAccount',
        _context: 'SignUpForm,AuthPage',
      });
    case 'createEvidence':
      return t('Create evidence', {
        _key: 'createEvidence',
        _context: 'EvidenceFormsDialog,EvidenceFormsList,HelpDocumentsDialog',
      });
    case 'createDraftDocumentFail':
      return t('Failed to create draft document.', {
        _key: 'createDraftDocumentFail',
        _context: 'EvidenceTable,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList',
      });
    case 'createDraftDocumentSuccess':
      return t('Document draft was created.', {
        _key: 'createDraftDocumentSuccess',
        _context: 'EvidenceTable,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList',
      });
    case 'createDraftDocumentWithEvidenceFormFail':
      return t('Failed to create draft document with evidence form.', {
        _key: 'createDraftDocumentWithEvidenceFormFail',
        _context: 'EvidenceFormsList,HelpDocumentsDialog',
      });
    case 'createDraftDocumentWithEvidenceFormSuccess':
      return t('Document draft was created with evidence form.', {
        _key: 'createDraftDocumentWithEvidenceFormSuccess',
        _context: 'EvidenceFormsList,HelpDocumentsDialog',
      });
    case 'createNewVersion':
      return t('Create new version', {
        _key: 'createNewVersion',
        _context: 'EvidenceExpiredDocumentsList,EvidenceValidDocumentsList',
      });
    case 'createOrEditCultivation':
      return t('Create/Edit cultivation', {
        _key: 'createOrEditCultivation',
        _context: 'AssessmentCultivationEditDialog,OrganizationCultivationEditDialog',
      });
    case 'createdRequest':
      return t('Created request', {
        _key: 'createdRequest',
        _context: 'ApprovedOutgoingRequestDialog,DashboardRequestsBlock',
      });
    case 'criteria':
      return t('Criteria', {
        _key: 'criteria',
        _context: 'ControlPointGuidanceTab,EvidenceReviewControlPoint',
      });
    case 'criticality':
      return t('Criticality', {
        _key: 'criticality',
        _context: 'Filters,ControlPointHeader,EvidenceReviewControlPoint',
      });
    case 'cultivation':
      return t('Cultivation', {
        _key: 'cultivation',
        _context: 'AssessmentConfigDialog,OrganizationCultivationTable',
      });
    case 'cultivations':
      return t('Cultivations', {
        _key: 'cultivations',
        _context: 'AssessmentConfigDialog,AssessmentConfigMatrix,OrganizationPage',
      });
    case 'cultivationDeleteCustomErrorMessage':
      return t(
        'Cultivation {name} cannot be deleted as it is currently used in an open assessment.',
        {
          _key: 'cultivationDeleteCustomErrorMessage',
          _context: 'AssessmentConfigDialog,OrganizationCultivationTable',
        },
      );
    case 'cultivationType':
      return t('Cultivation type', {
        _key: 'cultivationType',
        _context:
          'AssessmentCultivationEditDialog,OrganizationCultivationEditDialog,OrganizationCultivationTable',
      });
    case 'dashboard':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="settings,DashboardPage"
          _key="dashboard"
          _str="Dashboard"
        />
      );
    case 'dateCorrectiveActionsReported':
      return t('Date corrective actions reported', {
        _key: 'dateCorrectiveActionsReported',
        _context: 'CorrectiveAction,EditCorrectiveActionDialog,NewCorrectiveActionForm',
      });
    case 'dateFormatErrorMsg':
      return t('Please enter the date in the correct format: DD/MM/YYYY', {
        _key: 'dateFormatErrorMsg',
        _context: 'CreateEvidenceSaveDialog,EvidenceEditDialog',
      });
    case 'dateNonConformityReported':
      return t('Date non-conformity reported', {
        _key: 'dateNonConformityReported',
        _context: 'NewNonConformityForm,NonConformity',
      });
    case 'delete':
      return t('Delete', {
        _key: 'delete',
        _context:
          'MobileCompatibleDataGrid,EvidenceDeleteDialog,GenericDeleteDialog,OrganizationDetailsForm,SelectionListKebabMenu,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList,OrganizationCultivationTable,OrganizationInvitationTable,OrganizationUserTable,AssessmentArchivePage,DashboardRequestPage',
      });
    case 'details':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountPage,OrganizationPage"
          _key="details"
          _str="Details"
        />
      );
    case 'documentTitle':
      return t('Document title', {
        _key: 'documentTitle',
        _context: 'CreateEvidenceSaveDialog,EvidenceEditDialog',
      });
    case 'documentType':
      return t('Document type', {
        _key: 'documentType',
        _context:
          'ControlPointEvidenceDialog,EvidenceExpiredDocumentsList,EvidenceFormsList,EvidenceValidDocumentsList,FormikEvidenceTypeField',
      });
    case 'documents':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="settings,EvidenceTabsPage"
          _key="documents"
          _str="Documents"
        />
      );
    case 'download':
      return t('Download', {
        _key: 'download',
        _context:
          'EvidenceExpiredDocumentsList,EvidenceFormsDialog,EvidenceFormsList,EvidencePreviewDialog,EvidenceValidDocumentsList,HelpDocumentPreviewDialog,HelpDocumentsDialog,DocumentPreview',
      });
    case 'edit':
      return t('Edit', {
        _key: 'edit',
        _context:
          'EvidenceTableUploadDialog,DocumentPreview,ControlPointEvidenceDialog,SelectionListKebabMenu,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList,OrganizationCultivationTable,OrganizationInvitationTable,OrganizationUserTable',
      });
    case 'editAssessmentSetup':
      return t('Edit assessment setup', {
        _key: 'editAssessmentSetup',
        _context: 'AssessmentConfigActionBar,AssessmentConfigStep',
      });
    case 'editCorrectiveAction':
      return t('Edit corrective action', {
        _key: 'editCorrectiveAction',
        _context: 'CorrectiveAction,EditCorrectiveActionDialog',
      });
    case 'editInspectionSetup':
      return t('Edit inspection setup', {
        _key: 'editInspectionSetup',
        _context: 'AssessmentConfigActionBar,AssessmentConfigStep',
      });
    case 'editNonConformity':
      return t('Edit non-conformity', {
        _key: 'editNonConformity',
        _context: 'EditNonConformityDialog,NonConformity',
      });
    case 'editingDialogTitle':
      return t('Editing {name}', {
        _key: 'editingDialogTitle',
        _context:
          'EvidenceTableUploadDialog,AssessmentConfigDialog,ControlPointEvidenceDialog,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList,OrganizationCultivationTable,OrganizationInvitationTable,OrganizationUserTable',
        ...params,
      });
    case 'email':
      return t('E-mail', {
        _key: 'email',
        _context:
          'OrganizationInvitationEditDialog,OrganizationInvitationTable,OrganizationUserEditDialog,OrganizationUserTable,SignUpForm,ForgotPasswordPage,LoginPage,ResetPasswordPage',
      });
    case 'evidenceForms':
      return t('Evidence forms', {
        _key: 'evidenceForms',
        _context: 'EvidenceFormsDialog,EvidenceTabsPage',
      });
    case 'evidenceFormsAndGuidelines':
      return t('Evidence forms & guidelines', {
        _key: 'evidenceFormsAndGuidelines',
        _context: 'ControlPointHeaderKebabMenu,HelpDocumentsDialog',
      });
    case 'evidenceTableNoRowsMsg':
      return t(
        'You don’t have any documents yet.\nUpload a document to your self-assessment and it will appear here.',
        {
          _key: 'evidenceTableNoRowsMsg',
          _context: 'EvidenceExpiredDocumentsList,EvidenceFormsList,EvidenceValidDocumentsList',
        },
      );
    case 'expiredDocuments':
      return t('Expired documents', {
        _key: 'expiredDocuments',
        _context: 'EvidenceExpiredDocumentsList,EvidenceTabsPage',
      });
    case 'expiryDate':
      return t('Expiry date', {
        _key: 'expiryDate',
        _context:
          'AssessmentAuditorEvidenceStep,EvidenceTable,ControlPointEvidenceDialog,CreateEvidenceSaveDialog,EvidenceEditDialog,EvidenceExpiredDocumentsList,EvidenceFormsDialog,EvidenceFormsList,EvidenceValidDocumentsList,HelpDocumentsDialog',
      });
    case 'failedToCreateAssessment':
      return t('Failed to create assessment.', {
        _key: 'failedToCreateAssessment',
        _context: 'AssessmentCreateDialog,DashboardAssessmentsBlock,AssessmentArchivePage',
      });
    case 'farmOrganizationName':
      return t('Farm organisation name', {
        _key: 'farmOrganizationName',
        _context: 'AssessmentArchivePage, AssessmentKebabMenu',
      });
    case 'filters':
      return t('Filters', {
        _key: 'filters',
        _context: 'AssessmentControlPointStep,MobileCompatibleDataGrid',
      });
    case 'finish':
      return t('Finish', {
        _key: 'finish',
        _context: 'AssessmentActionBarButtons,AssessmentReportStep',
      });
    case 'forgotPassword':
      return t('Forgot password', {
        _key: 'forgotPassword',
        _context: 'settings,ForgotPasswordPage',
      });
    case 'fullName':
      return t('Full name', {
        _key: 'fullName',
        _context:
          'AccountDetailsForm,OrganizationInvitationEditDialog,SignUpForm,ChangeTemporaryPasswordPage',
      });
    case 'general':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm,OrganizationDetailsForm"
          _key="general"
          _str="General"
        />
      );
    case 'inspectionSetup':
      return t('Inspection setup', {
        _key: 'inspectionSetup',
        _context: 'AssessmentConfigDialog,AssessmentConfigStep',
      });
    case 'inspections':
      return t('Inspections', {
        _key: 'inspections',
        _context: 'AppPrimaryNavigation,AssessmentArchivePage,DashboardPage',
      });
    case 'invalidEmail':
      return t('Invalid e-mail', {
        _key: 'invalidEmail',
        _context:
          'AssessmentShareWithAuditorDialog,OrganizationDetailsForm,SignUpForm,ForgotPasswordPage,LoginPage',
      });
    case 'invitations':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="OrganizationInvitationTable,AccountPage"
          _key="invitations"
          _str="Invitations"
        />
      );
    case 'language':
      return t('Language', {
        _key: 'language',
        _context: 'AccountDetailsForm,SignUpForm,ChangeTemporaryPasswordPage',
      });
    case 'languageAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm,SignUpForm,ChangeTemporaryPasswordPage"
          _key="language"
          _str="Language"
        />
      );
    case 'linkWasCopied':
      return t('The link was copied!', {
        _key: 'linkWasCopied',
        _context: 'AssessmentShareDialog,AssessmentShareWithAuditorDialog,CopyToClipboardText',
      });
    case 'logIn':
      return t('Log in', {
        _key: 'logIn',
        _context: 'settings,AuthPage,LoginPage',
      });
    case 'logOut':
      return t('Log out', {
        _key: 'logOut',
        _context: 'AccountDetailsForm,AppAccountMenu',
      });
    case 'malusPoints':
      return t('MALUS POINTS ', {
        _key: 'malusPoints',
        _context: 'AssessmentConfigMatrix,HeaderLabel',
      });
    case 'minDateErrorMessage':
      return t('Date cannot be in the past. Please select a future date.', {
        _key: 'minDateErrorMessage',
        _context: 'CreateEvidenceSaveDialog,EvidenceEditDialog',
      });
    case 'maxErrorMessage':
      return t('Must be less than {max}', {
        _key: 'maxErrorMessage',
        _context: 'ControlPointDynamicAnswer,ControlPointInputAnswer,getNumberInputValidation',
        ...params,
      });
    case 'minErrorMessage':
      return t('Must be greater than {min}', {
        _key: 'minErrorMessage',
        _context:
          'AssessmentPreliminaryQuestionsStep.utils,ControlPointDynamicAnswer,ControlPointInputAnswer,getNumberInputValidation',
        ...params,
      });
    case 'name':
      return t('Name', {
        _key: 'name',
        _context:
          'AssessmentAuditorEvidenceStep,EvidenceTable,ControlPointEvidenceDialog,EvidenceExpiredDocumentsList,EvidenceFormsDialog,EvidenceFormsList,EvidenceValidDocumentsList,HelpDocumentsDialog,OrganizationInvitationTable,OrganizationUserEditDialog,OrganizationUserTable,AssessmentArchivePage',
      });
    case 'newPassword':
      return t('New password', {
        _key: 'newPassword',
        _context: 'ChangePasswordForm,ChangeTemporaryPasswordPage,ResetPasswordPage',
      });
    case 'newPasswordRule':
      return t(
        'Must contain at least 8 characters, including one uppercase, one lowercase, one number, and one special character.',
        {
          _key: 'newPasswordRule',
          _context: 'ChangePasswordForm,SignUpForm,ChangeTemporaryPasswordPage',
        },
      );
    case 'next':
      return t('Next', {
        _key: 'next',
        _context: 'AssessmentConfigDialog,OrganizationDetailsForm,TabbedPage',
      });
    case 'no':
      return t('No', {
        _key: 'no',
        _context: 'AssessmentAutoAttachRequestDialog,AssessmentCreateDialog',
      });
    case 'nonCompliant':
      return t('Non-compliant', {
        _key: 'nonCompliant',
        _context: 'AssessmentFinishDialogForCBManagers,AssessmentArchivePage',
      });
    case 'nonConformities':
      return t('Non-conformities', {
        _key: 'nonConformities',
        _context: 'Filters,ControlPointNonConformities',
      });
    case 'notApplicableShort':
      return t('N/A', {
        _key: 'notApplicableShort',
        _context: 'AssessmentArchivePage,DashboardRequestPage',
      });
    case 'notAuthorized':
      return t('You are not authorized for this operation.', {
        _key: 'notAuthorized',
        _context:
          'AssessmentConfigStep,AssessmentPreliminaryQuestionsStep.hooks,ControlPointAnswer,Comment,NewCommentForm,EvidenceAttachToggle,ControlPointEvidenceDialog,NonConformity,NewCorrectiveActionForm,ControlPointUploadedEvidence,FormikDialog,GenericDeleteDialog',
      });
    case 'organizationProfile':
      return t('Organisation profile', {
        _key: 'organizationProfile',
        _context: 'DashboardOrganizationBlock,OrganizationPage',
      });
    case 'organizationProfileDynamic':
      return t('{organizationName} profile', {
        _key: 'organizationProfileDynamic',
        _context: 'DashboardOrganizationBlock,OrganizationPage',
        ...params,
      });
    case 'password':
      return t('Password', {
        _key: 'password',
        _context: 'AccountDetailsForm,SignUpForm,LoginPage',
      });
    case 'passwordAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm,SignUpForm,LoginPage"
          _key="password"
          _str="Password"
        />
      );
    case 'passwordsNotMatch':
      return t('Passwords must match.', {
        _key: 'passwordsNotMatch',
        _context: 'ChangePasswordForm,SignUpForm,ChangeTemporaryPasswordPage',
      });
    case 'pleaseSelectDocumentType':
      return t('Please select document type', {
        _key: 'pleaseSelectDocumentType',
        _context: 'CreateEvidenceSaveDialog,EvidenceEditDialog',
      });
    case 'plotLabel':
      return t('Plot (ex. Field by the fence)', {
        _key: 'plotLabel',
        _context: 'AssessmentCultivationEditDialog,OrganizationCultivationEditDialog',
      });
    case 'plotSurfaceArea':
      return t('Plot surface area (ha)', {
        _key: 'plotSurfaceArea',
        _context:
          'AssessmentCultivationEditDialog,OrganizationCultivationEditDialog,OrganizationCultivationTable',
      });
    case 'postalCode':
      return t('Postal code', {
        _key: 'postalCode',
        _context: 'OrganizationDetailsForm,AssessmentKebabMenu,AssessmentCreateDialog',
      });
    case 'preloaded':
      return t('Preloaded', {
        _key: 'preloaded',
        _context: 'AssessmentAuditorEvidenceStep,EvidenceTable',
      });
    case 'product':
      return t('Product', {
        _key: 'product',
        _context: 'FormikProductField,OrganizationCultivationTable',
      });
    case 'publishedBy':
      return t('Published by', {
        _key: 'publishedBy',
        _context: 'EvidenceFormsDialog,EvidenceFormsList,HelpDocumentsDialog',
      });
    case 'requestApproved':
      return t('Request approved', {
        _key: 'requestApproved',
        _context: 'ApprovedOutgoingRequestDialog,PendingIncomingRequestDialog',
      });
    case 'requests':
      return t('Requests', {
        _key: 'requests',
        _context: 'settings,DashboardRequestPage',
      });
    case 'required':
      return t('Required', {
        _key: 'required',
        _context:
          'AccountDetailsForm,AssessmentConfigClientIdentifierForm,AssessmentCreateDialog,AssessmentCultivationEditDialog,AssessmentFinishDialogForCBManagers,ChangePasswordForm,EditNonConformityDialog,EditCorrectiveActionDialog,CreateEvidenceSaveDialog,OrganizationClientIdentifierForm,OrganizationCultivationEditDialog,OrganizationDetailsForm,OrganizationInvitationEditDialog,OrganizationUserEditDialog,PendingIncomingRequestDialog,SignUpForm,ChangeTemporaryPasswordPage,ForgotPasswordPage,LoginPage,ResetPasswordPage',
      });
    case 'resetPassword':
      return t('Reset password', {
        _key: 'resetPassword',
        _context: 'settings,ResetPasswordPage',
      });
    case 'role':
      return t('Role', {
        _key: 'role',
        _context:
          'AccountInvitationTable,OrganizationInvitationEditDialog,OrganizationInvitationTable,OrganizationUserEditDialog,OrganizationUserTable',
      });
    case 'save':
      return t('Save', {
        _key: 'save',
        _context:
          'AssessmentCultivationEditDialog,EvidenceTableUploadDialog,ControlPointEvidenceDialog,CreateEvidenceSaveDialog,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList,OrganizationCultivationEditDialog',
      });
    case 'saveAs':
      return t('Save as', {
        _key: 'saveAs',
        _context: 'CreateEvidenceDialog,CreateEvidenceSaveDialog',
      });
    case 'saveChanges':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm,AccountNotificationsForm,FormikDialog,OrganizationDetailsForm"
          _key="saveChanges"
          _str="Save changes"
        />
      );
    case 'selfAssessments':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="settings,AssessmentArchivePage,DashboardPage"
          _key="selfAssessments"
          _str="Self assessments"
        />
      );
    case 'share':
      return t('Share', {
        _key: 'share',
        _context: 'AssessmentReportStep,AssessmentArchivePage',
      });
    case 'shareInspectionToColleagues':
      return t('Use the link below to share this inspection with your colleagues:', {
        _key: 'shareInspectionToColleagues',
        _context: 'AssessmentFinishDialogForAuditors,AssessmentShareDialog',
      });
    case 'standards':
      return t('Standards', {
        _key: 'standards',
        _context: 'AssessmentConfigDialog,AssessmentControlPointModulesDrawer',
      });
    case 'standard':
      return t('Standard', {
        _key: 'standard',
        _context: 'AssessmentArchivePage,MobileCompatibleDataGrid',
      });
    case 'startNewSelfAssessment':
      return t('Start new self assessment', {
        _key: 'startNewSelfAssessment',
        _context: 'AssessmentArchivePage,DashboardPage',
      });
    case 'status':
      return t('Status', {
        _key: 'status',
        _context:
          'AccountInvitationTable,AssessmentAuditorEvidenceStep,EvidenceTable,OrganizationInvitationTable,OrganizationUserTable,AssessmentArchivePage,DashboardRequestPage',
      });
    case 'startInspection':
      return t('Start inspection', {
        _key: 'startInspection',
        _context: 'AssessmentCreateDialog,AssessmentArchivePage,DashboardPage',
      });
    case 'timeNonConformityAppear':
      return t('Time non-conformity appear', {
        _key: 'timeNonConformityAppear',
        _context: 'EditNonConformityDialog,NewNonConformityForm,NonConformity',
      });
    case 'type':
      return t('Type', {
        _key: 'type',
        _context: 'EvidenceFormsDialog,HelpDocumentsDialog,AssessmentArchivePage',
      });
    case 'unknown':
      return t('Unknown', {
        _key: 'unknown',
        _context: 'FormikDialog,createDataGridLinkedIdValueGetter',
      });
    case 'unknownErrorOccurred':
      return t('An unknown error occurred.', {
        _key: 'unknownErrorOccurred',
        _context: 'helpers,auth',
      });
    case 'update':
      return t('Update', {
        _key: 'update',
        _context: 'EvidenceExpiredDocumentsList,EvidenceTable',
      });
    case 'upload':
      return t('Upload', {
        _key: 'upload',
        _context: 'EvidenceEditDialog,EvidenceValidDocumentsList,OrganizationDetailsForm',
      });
    case 'uploadedDate':
      return t('Uploaded date', {
        _key: 'uploadedDate',
        _context: 'EvidenceFormsList,HelpDocumentsDialog',
      });
    case 'uploadDate':
      return t('Upload date', {
        _key: 'uploadDate',
        _context:
          'ControlPointEvidenceDialog,EvidenceExpiredDocumentsList,EvidenceValidDocumentsList',
      });
    case 'uploadDocument':
      return t('Upload a document', {
        _key: 'uploadDocument',
        _context: 'EvidenceTableUploadDialog,ControlPointEvidenceDialog,EvidenceValidDocumentsList',
      });
    case 'uploadNewDocument':
      return t('Upload a new document', {
        _key: 'uploadNewDocument',
        _context: 'EvidenceTableUploadDialog,ControlPointEvidenceDialog,EvidenceValidDocumentsList',
      });
    case 'uploadDocumentTableEmptyMsg':
      return t('Upload a document and it will appear here.', {
        _key: 'uploadDocumentTableEmptyMsg',
        _context: 'EvidenceTable,ControlPointEvidenceDialog',
      });
    case 'viewingPreviewTitle':
      return t('Viewing {name}', {
        _key: 'viewingPreviewTitle',
        _context: 'EvidencePreviewDialog,HelpDocumentPreviewDialog',
        ...params,
      });
    case 'yes':
      return t('Yes', {
        _key: 'yes',
        _context: 'AssessmentAutoAttachRequestDialog,AssessmentCreateDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const accountDetailsFormStrings = (key: AccountDetailsFormStringsKeyType): string => {
  switch (key) {
    case 'account':
      return t('Account', {
        _key: 'account',
        _context: 'AccountDetailsForm',
      });
    case 'deactivateAccount':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm"
          _key="deactivateAccount"
          _str="Deactivate account"
        />
      );
    case 'deactivateAccountWarningMessage':
      // Because of Transifex small t function refresh problem.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm"
          _key="deactivateAccountWarningMessage"
          _str="This will remove your account from the company and disable your account."
        />
      );
    case 'emailAddress':
      return t('E-mail address', {
        _key: 'emailAddress',
        _context: 'AccountDetailsForm',
      });
    case 'emailAddressAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm"
          _key="emailAddress"
          _str="E-mail address"
        />
      );
    case 'fullNameAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      // @ts-expect-error transifex type issue
      return (
        <T
          _context="AccountDetailsForm"
          _key="fullName"
          _str="Full name"
        />
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const accountInvitationTableStrings = (
  key: AccountInvitationTableStringsKeyType,
): string => {
  switch (key) {
    case 'declineInvitation':
      return t('Decline invitation', {
        _key: 'declineInvitation',
        _context: 'AccountInvitationTable',
      });
    case 'declineInvitationFail':
      return t('Failed to decline invitation.', {
        _key: 'declineInvitationFail',
        _context: 'AccountInvitationTable',
      });
    case 'declineInvitationSuccess':
      return t('Successfully decline invitation.', {
        _key: 'declineInvitationSuccess',
        _context: 'AccountInvitationTable',
      });
    case 'invitationTableNoRowsMsg':
      return t('When an organisation invites you to join, its invitation will appear here.', {
        _key: 'invitationTableNoRowsMsg',
        _context: 'AccountInvitationTable',
      });
    case 'invitingOrganization':
      return t('Inviting organisation', {
        _key: 'invitingOrganization',
        _context: 'AccountInvitationTable',
      });
    case 'join':
      return t('Join', {
        _key: 'join',
        _context: 'AccountInvitationTable',
      });
    case 'joinOrganizationFail':
      return t('Failed to join organisation.', {
        _key: 'joinOrganizationFail',
        _context: 'AccountInvitationTable',
      });
    case 'joinOrganizationSuccess':
      return t('Joining in the organisation was successful.', {
        _key: 'joinOrganizationSuccess',
        _context: 'AccountInvitationTable',
      });

    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const accountNotificationsFormStrings = (
  key: AccountNotificationsFormStringsKeyType,
): string => {
  switch (key) {
    case 'notification':
      return t('Notification', {
        _key: 'notification',
        _context: 'OrganizationDetailsForm',
      });
    case 'sendEmailForAllNotifications':
      return t('Send an e-mail for all notifications', {
        _key: 'sendEmailForAllNotifications',
        _context: 'OrganizationDetailsForm',
      });
    case 'sendEmailOnlyForComments':
      return t('Send an e-mail only for a new comments', {
        _key: 'sendEmailOnlyForComments',
        _context: 'OrganizationDetailsForm',
      });
    case 'sendEmailOnlyForRequests':
      return t('Send an e-mail only for a new request approval', {
        _key: 'sendEmailOnlyForRequests',
        _context: 'OrganizationDetailsForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const accountPageStrings = (key: AccountPageStringsKeyType): React.JSX.Element => {
  switch (key) {
    case 'notifications':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="AccountPage"
          _key="notifications"
          _str="Notifications"
        />
      );
    case 'successfullySavedChanges':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="AccountPage"
          _key="successfullySavedChanges"
          _str="Successfully saved changes."
        />
      );
    case 'userAccount':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="AccountPage"
          _key="userAccount"
          _str="User account"
        />
      );
    default:
      return (
        <T
          _key="translationNotFound"
          _str="Translation not found!"
        />
      );
  }
};

export const appLogoStrings = (key: AppLogoStringsKeyType): string => {
  switch (key) {
    case 'createAccountPlanetProofMessage':
      return t(' certification, please create the account or log in so we can save your answers.', {
        _key: 'createAccountPlanetProofMessage',
        _context: 'AppLogo',
      });
    case 'toApplyFor':
      return t('To apply for ', {
        _key: 'toApplyFor',
        _context: 'AppLogo',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const appOrganizationSwitcherStrings = (
  key: AppOrganizationSwitcherStringsKeyType,
): string => {
  switch (key) {
    case 'addNewFarm':
      return t('Add new Farm', {
        _key: 'addNewFarm',
        _context: 'AppOrganizationSwitcher',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const appPrimaryNavigationStrings = (
  key: AppPrimaryNavigationStringsKeyType,
): string | React.JSX.Element => {
  switch (key) {
    case 'allProviderAnswersWereAutosaved':
      return t('All provided answers were autosaved.', {
        _key: 'allProviderAnswersWereAutosaved',
        _context: 'AppPrimaryNavigation',
      });
    case 'overview':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="AppPrimaryNavigation"
          _key="overview"
          _str="Overview"
        />
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const approvedOutgoingRequestDialogStrings = (
  key: ApprovedOutgoingRequestDialogStringsKeyType,
): string => {
  switch (key) {
    case 'approvedOutgoingRequestDialogMessage':
      return t(
        'The farm manager\'s data has been successfully imported and you can now create a connected inspection, saving you time filling in the data.',
        {
          _key: 'approvedOutgoingRequestDialogMessage',
          _context: 'ApprovedOutgoingRequestDialog',
        },
      );
    case 'createConnectedAssessmentFail':
      return t('Failed to create connected assessment.', {
        _key: 'createConnectedAssessmentFail',
        _context: 'ApprovedOutgoingRequestDialog',
      });
    case 'createConnectedAssessmentSuccess':
      return t('Created connected assessment successfully.', {
        _key: 'createConnectedAssessmentSuccess',
        _context: 'ApprovedOutgoingRequestDialog',
      });
    case 'createConnectedInspection':
      return t('Create connected inspection', {
        _key: 'createConnectedInspection',
        _context: 'ApprovedOutgoingRequestDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentActionBarButtonsStrings = (
  key: AssessmentActionBarButtonsStringsKeyType,
  params?: { nextTaskName?: string },
): string => {
  switch (key) {
    case 'continue':
      return t('Continue', {
        _key: 'continue',
        _context: 'AssessmentActionBarButtons',
      });
    case 'continueToNextTask':
      return t('Continue to {nextTaskName}', {
        _key: 'continueToNextTask',
        _context: 'AssessmentActionBarButtons',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentArchivePageStrings = (key: AssessmentArchivePageStringsKeyType): string => {
  switch (key) {
    case 'assessment':
      return t('Assessment', {
        _key: 'assessment',
        _context: 'settings,AssessmentArchivePage',
      });
    case 'assessmentTableNoRowsMsg':
      return t(
        'You don’t have any self assessments yet.\nStart your new self assessment and come back later to easily find it.',
        {
          _key: 'assessmentTableNoRowsMsg',
          _context: 'AssessmentArchivePage',
        },
      );
    case 'assessmentTableNoRowsMsgForAuditors':
      return t(
        'You don’t have any inspections yet.\nStart your new inspection and come back later to easily find it.',
        {
          _key: 'assessmentTableNoRowsMsgForAuditors',
          _context: 'AssessmentArchivePage',
        },
      );
    case 'auditDate':
      return t('Audit date', {
        _key: 'auditDate',
        _context: 'AssessmentArchivePage',
      });
    case 'auditorName':
      return t('Auditor name', {
        _key: 'auditorName',
        _context: 'AssessmentArchivePage',
      });
    case 'clientIdentifiers':
      return t('Client identifiers', {
        _key: 'clientIdentifiers',
        _context: 'AssessmentArchivePage',
      });
    case 'completedOn':
      return t('Completed on', {
        _key: 'completedOn',
        _context: 'AssessmentArchivePage',
      });
    case 'decisionOrConclusion':
      return t('Decision/Conclusion', {
        _key: 'decisionOrConclusion',
        _context: 'AssessmentArchivePage',
      });
    case 'includedStandards':
      return t('Included standards', {
        _key: 'includedStandards',
        _context: 'AssessmentArchivePage',
      });
    case 'inspection':
      return t('Inspection', {
        _key: 'inspection',
        _context: 'AssessmentArchivePage',
      });
    case 'retailers':
      return t('Retailers', {
        _key: 'retailers',
        _context: 'AssessmentArchivePage',
      });
    case 'selfAssessment':
      return t('Self assessment', {
        _key: 'selfAssessment',
        _context: 'AssessmentArchivePage',
      });
    case 'startedOn':
      return t('Started on', {
        _key: 'startedOn',
        _context: 'AssessmentArchivePage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentAuditorEvidenceStepStrings = (
  key: AssessmentAuditorEvidenceStepStringsKeyType,
  params?: { attachedToControlPointCount?: number },
): string => {
  switch (key) {
    case 'evidenceReviewStepDescriptionForAuditor':
      return t(
        'Evidence is listed in order of the number of control points it has been attached to. You can review, attach, and navigate between evidence by clicking "Review & attach"',
        {
          _key: 'evidenceReviewStepDescriptionForAuditor',
          _context: 'AssessmentAuditorEvidenceStep',
        },
      );
    case 'noEvidenceWasProvided':
      return t(
        'No evidence was provided by farm manager within the self assessment.\nYou can attach evidence per question on the next Checklist step.',
        {
          _key: 'noEvidenceWasProvided',
          _context: 'AssessmentAuditorEvidenceStep',
        },
      );
    case 'questionsWithNumber':
      return t('{attachedToControlPointCount} question(s)', {
        _key: 'questionsWithNumber',
        _context: 'AssessmentAuditorEvidenceStep',
        ...params,
      });
    case 'reviewAndAttach':
      return t('Review & attach', {
        _key: 'reviewAndAttach',
        _context: 'AssessmentAuditorEvidenceStep',
      });
    case 'reviewEvidenceAttachedByTheFarmManager':
      return t('Review the evidence attached by the farm manager during their self assessment', {
        _key: 'reviewEvidenceAttachedByTheFarmManager',
        _context: 'AssessmentAuditorEvidenceStep',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentAutoAttachRequestDialogStrings = (
  key: AssessmentAutoAttachRequestDialogStringsKeyType,
): string => {
  switch (key) {
    case 'youCanBenefitFromAutomaticReuse':
      return t(
        'By starting fresh now, you can benefit from automatic attachments in future assessments.',
        {
          _key: 'youCanBenefitFromAutomaticReuse',
          _context: 'AssessmentAutoAttachRequestDialog',
        },
      );
    case 'doYouWantToAutoAttach':
      return t('Reuse documents from last year?', {
        _key: 'doYouWantToAutoAttach',
        _context: 'AssessmentAutoAttachRequestDialog',
      });
    case 'ifYouPreferManuallyConfirmAttachments':
      return t(
        'Not sure? Then we recommend manually confirming the suggested attachments to avoid incorrect attachments. A clean document list enhances the audit process—click ',
        {
          _key: 'ifYouPreferManuallyConfirmAttachments',
          _context: 'AssessmentAutoAttachRequestDialog',
        },
      );
    case 'ifYouWantCanAutoAttached':
      return t(
        'Have you kept your documents thoroughly updated? If so, auto-attachment might be a good option—click',
        {
          _key: 'ifYouWantCanAutoAttached',
          _context: 'AssessmentAutoAttachRequestDialog',
        },
      );
    case 'choiceAppliesOnlyToThisAssessment':
      return t(
        'Note: This choice applies only to this assessment. If you\'re not satisfied with the result, you can start a new assessment.',
        {
          _key: 'choiceAppliesOnlyToThisAssessment',
          _context: 'AssessmentAutoAttachRequestDialog',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentConfigActionBarStrings = (
  key: AssessmentConfigActionBarStringsKeyType,
): string => {
  switch (key) {
    case 'configureAtLeastOneCultivation':
      return t('Please configure the assessment with at least one cultivation and one standard.', {
        _key: 'configureAtLeastOneCultivation',
        _context: 'AssessmentConfigActionBar',
      });
    case 'selectAtLeastOneCultivation':
      return t('Please select at least one cultivation and standard combination.', {
        _key: 'selectAtLeastOneCultivation',
        _context: 'AssessmentConfigActionBar',
      });
    case 'malusPointsAreRequired':
      return t('Malus points are required. Please enter malus points for each cultivations.', {
        _key: 'malusPointsAreRequired',
        _context: 'AssessmentConfigActionBar',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentConfigDialogStrings = (
  key: AssessmentConfigDialogStringsKeyType,
  params?: { step?: number },
): string => {
  switch (key) {
    case 'addOrVerifyIdentificationNumbers':
      return t(
        'Add or verify certification organisation(s), and your respective client ID(s), that will be auditing and issuing the following certificates:',
        {
          _key: 'addOrVerifyIdentificationNumbers',
          _context: 'AssessmentConfigDialog',
        },
      );
    case 'assessmentSetup':
      return t('Assessment setup', {
        _key: 'assessmentSetup',
        _context: 'AssessmentConfigDialog',
      });
    case 'certificates':
      return t('Certificates', {
        _key: 'certificates',
        _context: 'AssessmentConfigDialog',
      });
    case 'dialogStep':
      return t('Step {step}/2', {
        _key: 'dialogStep',
        _context: 'AssessmentConfigDialog',
        ...params,
      });
    case 'getAccessWithUpgradedSubscriptionPlans':
      return t(
        'Get access to more certificates and combined preparation functionality with upgraded subscription plans. ',
        {
          _key: 'getAccessWithUpgradedSubscriptionPlans',
          _context: 'AssessmentConfigDialog',
        },
      );
    case 'missingClientIDForFarmEmployee':
      return t(
        'Missing ID number(s). Ask organisation owner or manager to fill in client ID(s) for the certifications included into this setup to continue.',
        {
          _key: 'missingClientIDForFarmEmployee',
          _context: 'AssessmentConfigDialog',
        },
      );
    case 'selectOrAddCultivationAndCertificate':
      return t('Select or add cultivation(s) and certificate(s) for which you wish to apply.', {
        _key: 'selectOrAddCultivationAndCertificate',
        _context: 'AssessmentConfigDialog',
      });
    case 'selectOrAddCultivationAndCertificateForAuditors':
      return t(
        'Select or add cultivation(s) and standard(s) that should be included in the inspection.',
        {
          _key: 'selectOrAddCultivationAndCertificateForAuditors',
          _context: 'AssessmentConfigDialog',
        },
      );
    case 'unlockMoreFeatures':
      return t('Unlock more features!', {
        _key: 'unlockMoreFeatures',
        _context: 'AssessmentConfigDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentConfigMatrixStrings = (
  key: AssessmentConfigMatrixStringsKeyType,
  params?: { cellLabel?: string },
): string => {
  switch (key) {
    case 'certificationAppliesToWholeFarm':
      return t(
        '{cellLabel} certification applies to the whole farm so there is no need to select cultivation for it, it will still be included in the assessment',
        {
          _key: 'certificationAppliesToWholeFarm',
          _tags: 'CheckboxCell',
          ...params,
        },
      );
    case 'cultivationsExcludedFromAssessment':
      return t('CULTIVATIONS EXCLUDED FROM THIS ASSESSMENT', {
        _key: 'cultivationsExcludedFromAssessment',
        _context: 'AssessmentConfigMatrix',
      });
    case 'cultivationsExcludedFromInspection':
      return t('CULTIVATIONS EXCLUDED FROM THIS INSPECTION', {
        _key: 'cultivationsExcludedFromInspection',
        _context: 'AssessmentConfigMatrix',
      });
    case 'excludePPTooltip':
      return t(
        'This product has been excluded because there is another similar product in the assessment with a higher malus points.',
        {
          _key: 'excludePPTooltip',
          _context: 'AssessmentConfigMatrix',
        },
      );
    case 'excludeSMKTooltip':
      return t(
        'This product can not be selected for this standard, select an alternative product or contact the standard setter to learn why it has been excluded from the product list.',
        {
          _key: 'excludeSMKTooltip',
          _context: 'AssessmentConfigMatrix',
        },
      );
    case 'howToCalculateMalusPoints':
      return t('How to calculate malus points?', {
        _key: 'howToCalculateMalusPoints',
        _context: 'AssessmentConfigMatrix',
      });
    case 'leafReviewMailInfo':
      return t(
        'These questions have been included based on information provided by the retailers and other LEAF partners. If any of the above is incorrect, please contact LEAF at',
        {
          _key: 'leafReviewMailInfo',
          _context: 'AssessmentConfigMatrix',
        },
      );
    case 'malusPointsDescriptionWithLink':
      return t(
        'For On the way to PlanetProof standard please indicate malus points for each cultivation without glyphosate (2.11) and cleansers/disinfectants (7.3). Check instructions',
        {
          _key: 'malusPointsDescriptionWithLink',
          _context: 'AssessmentConfigMatrix',
        },
      );
    case 'thereIsNoRecordOfAnyRetailers':
      return t(
        'There is no record of any retailers or other leaf partners associated with your membership',
        {
          _key: 'thereIsNoRecordOfAnyRetailers',
          _context: 'AssessmentConfigMatrix',
        },
      );
    case 'youWillSeeAdditionalQuestionsFrom':
      return t('You will see additional questions from', {
        _key: 'youWillSeeAdditionalQuestionsFrom',
        _context: 'AssessmentConfigMatrix',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentConfigStepStrings = (key: AssessmentConfigStepStringsKeyType): string => {
  switch (key) {
    case 'assessmentConfigStepDesc':
      return t(
        'Check each certification’s tab below and make sure only the relevant cultivations are included.',
        {
          _key: 'assessmentConfigStepDesc',
          _context: 'AssessmentConfigStep',
        },
      );
    case 'assessmentConfigStepDescriptionForAuditor':
      return t(
        'For each standard below, review the cultivations that should be included in the inspection.',
        {
          _key: 'assessmentConfigStepDescriptionForAuditor',
          _context: 'AssessmentConfigStep',
        },
      );
    case 'isEverythingSetUpCorrectly':
      return t('Is everything set up correctly?', {
        _key: 'isEverythingSetUpCorrectly',
        _context: 'AssessmentConfigStep',
      });
    case 'loadingMessage':
      return t('Just a moment while we configure the assessment for you...', {
        _key: 'loadingMessage',
        _context: 'AssessmentConfigStep',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentControlPointCategoriesDrawerStrings = (
  key: AssessmentControlPointCategoriesDrawerStringsKeyType,
): string => {
  switch (key) {
    case 'categories':
      return t('Categories', {
        _key: 'categories',
        _context: 'AssessmentControlPointCategoriesDrawer',
      });
    case 'chooseDifferentStandard':
      return t('Please go back and choose a different standard.', {
        _key: 'chooseDifferentStandard',
        _context: 'AssessmentControlPointCategoriesDrawer',
      });
    case 'hideCategories':
      return t('Hide categories', {
        _key: 'hideCategories',
        _context: 'AssessmentControlPointCategoriesDrawer',
      });
    case 'noCategoriesToDisplay':
      return t('No categories to display', {
        _key: 'noCategoriesToDisplay',
        _context: 'AssessmentControlPointCategoriesDrawer',
      });
    case 'showCategories':
      return t('Show categories', {
        _key: 'showCategories',
        _context: 'AssessmentControlPointCategoriesDrawer',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentControlPointDrawerToggleStrings = (
  key: AssessmentControlPointDrawerToggleStringsKeyType,
): string => {
  switch (key) {
    case 'auditingMode':
      return t('Auditing mode', {
        _key: 'auditingMode',
        _context: 'AssessmentControlPointDrawerToggle',
      });
    case 'selfAssessmentMode':
      return t('Self assessment mode', {
        _key: 'selfAssessmentMode',
        _context: 'AssessmentControlPointDrawerToggle',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentControlPointModulesDrawerStrings = (
  key: AssessmentControlPointModulesDrawerStringsKeyType,
): string => {
  switch (key) {
    case 'hideModules':
      return t('Hide modules', {
        _key: 'hideModules',
        _context: 'AssessmentControlPointModulesDrawer',
      });
    case 'showModules':
      return t('Show modules', {
        _key: 'showModules',
        _context: 'AssessmentControlPointModulesDrawer',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentControlPointStepProgressDialogStrings = (
  key: AssessmentControlPointStepProgressDialogStringsKeyType,
): string => {
  switch (key) {
    case 'allChapters':
      return t('All chapters', {
        _key: 'allChapters',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'answeredQuestionsAndTotal':
      return t('Answered questions / Total', {
        _key: 'answeredQuestionsAndTotal',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'autoFilledControlPoints':
      return t('auto-filled control points', {
        _key: 'autoFilledControlPoints',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'inspectionOverview':
      return t('Inspection Overview', {
        _key: 'inspectionOverview',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'inspectionProgressByChapter':
      return t('Inspection Progress by Chapter', {
        _key: 'inspectionProgressByChapter',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'inspectionProgressByStandard':
      return t('Inspection Progress by Standard', {
        _key: 'inspectionProgressByStandard',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'selfAssessmentOverview':
      return t('Self assessment Overview', {
        _key: 'selfAssessmentOverview',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'selfAssessmentProgressByChapter':
      return t('Self assessment Progress by Chapter', {
        _key: 'selfAssessmentProgressByChapter',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'selfAssessmentProgressByStandard':
      return t('Self assessment Progress by Standard', {
        _key: 'selfAssessmentProgressByStandard',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'totalInspectionProgress':
      return t('Total Inspection Progress', {
        _key: 'totalInspectionProgress',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    case 'totalSelfAssessmentProgress':
      return t('Total Self assessment Progress', {
        _key: 'totalSelfAssessmentProgress',
        _context: 'AssessmentControlPointStepProgressDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentControlPointStepStrings = (
  key: AssessmentControlPointStepStringsKeyType,
): string => {
  switch (key) {
    case 'assessmentReadyForApprovalSuccess':
      return t('Your assessment has been successfully made ready for approval!', {
        _key: 'assessmentReadyForApprovalSuccess',
        _context: 'AssessmentControlPointStep',
      });
    case 'completingChecklist':
      return t('Completing the checklist', {
        _key: 'completingChecklist',
        _context: 'AssessmentControlPointStep',
      });
    case 'controlPointStepSubtitle':
      return t(
        'All questions on the checklist have been grouped by category. Please provide an answer for each, and check those that have been automatically answered are correct. Under the [⋮] button you can find question descriptions, detailed criteria, and help documents. As well as the ability to read and leave comments, and review and attach additional evidence.',
        {
          _key: 'controlPointStepSubtitle',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'controlPointStepSubtitleForAuditor':
      return t(
        'Navigate between chapters with side bar or move through them sequentially with the "Next chapter" button. Answer the control points and check those that have been answered automatically by the system are correct. Under the [⋮] button you can find question descriptions, detailed criteria, and help documents. As well as the ability to read and leave comments, and review and attach additional evidence.',
        {
          _key: 'controlPointStepSubtitleForAuditor',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'controlPointStepSubtitleForSMK':
      return t(
        'Navigate between themes with side bar or move through them sequentially with the "Next theme" button. Answer the control points and check those that have been answered automatically by the system are correct. Under the [⋮] button you can find question descriptions, detailed criteria, and help documents. As well as the ability to read and leave comments, and review and attach additional evidence.',
        {
          _key: 'controlPointStepSubtitleForSMK',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'controlPointTableNoRowsMsgForAuditors':
      return t(
        'No questions to display.\nPlease try a different combination of filters or another chapter using the button below or the sidebar.',
        {
          _key: 'controlPointTableNoRowsMsgForAuditors',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'controlPointTableNoRowsMsgForFarmManagers':
      return t(
        'No questions to display.\nPlease try a different combination of filters or another category using the button below or the sidebar.',
        {
          _key: 'controlPointTableNoRowsMsgForFarmManagers',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'controlPointTableNoRowsMsgForSMK':
      return t(
        'No questions to display.\nPlease try a different combination of filters or another theme using the button below or the sidebar.',
        {
          _key: 'controlPointTableNoRowsMsgForSMK',
          _context: 'AssessmentControlPointStep',
        },
      );
    case 'nextCategory':
      return t('Next category', {
        _key: 'nextCategory',
        _context: 'AssessmentControlPointStep',
      });
    case 'nextChapter':
      return t('Next chapter', {
        _key: 'nextChapter',
        _context: 'AssessmentControlPointStep',
      });
    case 'nextTheme':
      return t('Next theme', {
        _key: 'nextTheme',
        _context: 'AssessmentControlPointStep',
      });
    case 'noQuestionsToDisplay':
      return t('No questions to display', {
        _key: 'noQuestionsToDisplay',
        _context: 'AssessmentControlPointStep',
      });
    case 'progress':
      return t('Progress', {
        _key: 'progress',
        _context: 'AssessmentControlPointStep',
      });
    case 'unknownCategory':
      return t('Unknown category', {
        _key: 'unknownCategory',
        _context: 'AssessmentControlPointStep',
      });
    case 'unknownModule':
      return t('Unknown module', {
        _key: 'unknownModule',
        _context: 'AssessmentControlPointStep',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentCreateDialogStrings = (
  key: AssessmentCreateDialogStringsKeyType,
): string => {
  switch (key) {
    case 'createRequestFail':
      return t('Failed to create request.', {
        _key: 'createRequestFail',
        _context: 'AssessmentCreateDialog',
      });
    case 'hasFarmManagerPreparedSelfAssessmentWithAgriplace':
      return t('Has the farm manager prepared self assessment with Agriplace?', {
        _key: 'hasFarmManagerPreparedSelfAssessmentWithAgriplace',
        _context: 'AssessmentCreateDialog',
      });
    case 'requestAccess':
      return t('Request access', {
        _key: 'requestAccess',
        _context: 'AssessmentCreateDialog',
      });
    case 'requestSent':
      return t('Request sent', {
        _key: 'requestSent',
        _context: 'AssessmentCreateDialog',
      });
    case 'selectStandard':
      return t('Select the standard', {
        _key: 'selectStandard',
        _context: 'AssessmentCreateDialog',
      });
    case 'startNewInspection':
      return t('Start new inspection', {
        _key: 'startNewInspection',
        _context: 'AssessmentCreateDialog',
      });
    case 'uniqueRegistrationNumber':
      return t('Unique registration number within selected standard', {
        _key: 'uniqueRegistrationNumber',
        _context: 'AssessmentCreateDialog',
      });
    case 'clientIdNumber':
      return t('Client ID number', {
        _key: 'clientIdNumber',
        _context: 'AssessmentCreateDialog',
      });
    case 'waitForRequestToBeApproved':
      return t('Please wait for request to be approved by', {
        _key: 'waitForRequestToBeApproved',
        _context: 'AssessmentCreateDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentEvidenceCategoryStrings = (
  key: AssessmentEvidenceCategoryStringsKeyType,
  params?: { evidenceTypeName?: string },
): string => {
  switch (key) {
    case 'provideRelatedQuestions':
      return t('Provide {evidenceTypeName} for questions:', {
        _key: 'provideRelatedQuestions',
        _context: 'AssessmentEvidenceCategory',
        ...params,
      });
    case 'readCriteria':
      return t('Read criteria', {
        _key: 'readCriteria',
        _context: 'AssessmentEvidenceCategory',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentEvidenceStepStrings = (
  key: AssessmentEvidenceStepStringsKeyType,
): string => {
  switch (key) {
    case 'attachingDocumentation':
      return t('Attaching documentation', {
        _key: 'attachingDocumentation',
        _context: 'AssessmentEvidenceStep',
      });
    case 'documentReviewStepDescription':
      return t(
        'At this step, you will find tabs with the documentation lists organised by level of criticality to provide it. On each tab questions have been grouped by category and documentation type required as evidence. You can select existing, or upload new evidence, to attach to multiple questions at once. Alternatively, you can always attach evidence per question during the Checklist step.',
        {
          _key: 'documentReviewStepDescription',
          _context: 'AssessmentEvidenceStep',
        },
      );
    case 'essentialDocumentation':
      return t('Essential documentation', {
        _key: 'essentialDocumentation',
        _context: 'AssessmentEvidenceStep',
      });
    case 'essentialDocumentationDesc':
      return t(
        'Essential documentation serve as evidence to control points which are mandatory to at least partially complete or complete with time such as Minors and 2nd, 3rd year criteria. Providing these documentation is highly recommended but may not result in immediate exclusion for certification.',
        {
          _key: 'essentialDocumentationDesc',
          _context: 'AssessmentEvidenceStepEssential',
        },
      );
    case 'mandatoryDocumentation':
      return t('Mandatory documentation', {
        _key: 'mandatoryDocumentation',
        _context: 'AssessmentEvidenceStep',
      });
    case 'mandatoryDocumentationDesc':
      return t(
        'Mandatory documentation serve as evidence to control points which are indicated as critical to comply with such as Critical Majors and Majors Musts. Failure to provide mandatory documentation may result in non-compliance during the auditing process.',
        {
          _key: 'mandatoryDocumentationDesc',
          _context: 'AssessmentEvidenceStep',
        },
      );
    case 'optionalDocumentation':
      return t('Optional documentation', {
        _key: 'optionalDocumentation',
        _context: 'AssessmentEvidenceStep',
      });
    case 'optionalDocumentationDesc':
      return t(
        'Optional documentation serve as evidence to optional control points such as measures or actions taken. These documentation can add valuable context and insights. They may be crucial in achieving the minimal amount of optional measures or serve as additional information/supporting documentation that you choose to include in the auditing process for clarity towards the auditor.',
        {
          _key: 'optionalDocumentationDesc',
          _context: 'AssessmentEvidenceStep',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentExportResultDialogStrings = (
  key: AssessmentExportResultDialogStringsKeyType,
): string => {
  switch (key) {
    case 'documentsExportIsInProgress':
      return t('Documents export is in progress', {
        _key: 'documentsExportIsInProgress',
        _context: 'AssessmentExportResultDialog',
      });
    case 'youWillReceiveEmailNotificationWhenExportIsReady':
      return t('You will receive email notification once the documents export is ready.', {
        _key: 'youWillReceiveEmailNotificationWhenExportIsReady',
        _context: 'AssessmentExportResultDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentFinishDialogForAuditorsStrings = (
  key: AssessmentFinishDialogForAuditorsStringsKeyType,
): string => {
  switch (key) {
    case 'backToEdit':
      return t('Back to edit', {
        _key: 'backToEdit',
        _context: 'AssessmentFinishDialogForAuditors',
      });
    case 'completeAssessmentSuccess':
      return t('Thank you for completing the inspection! All the data was saved.', {
        _key: 'completeAssessmentSuccess',
        _context: 'AssessmentFinishDialogForAuditors',
      });
    case 'copyLinkAndFinish':
      return t('Copy link and finish', {
        _key: 'copyLinkAndFinish',
        _context: 'AssessmentFinishDialogForAuditors',
      });
    case 'inspectionWasSaved':
      return t('Inspection was saved', {
        _key: 'inspectionWasSaved',
        _context: 'AssessmentFinishDialogForAuditors',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentFinishDialogForCBManagersStrings = (
  key: AssessmentFinishDialogForCBManagersStringsKeyType,
): string => {
  switch (key) {
    case 'auditConclusion':
      return t('Audit conclusion', {
        _key: 'auditConclusion',
        _context: 'AssessmentFinishDialogForCBManagers',
      });
    case 'conclusion':
      return t('Conclusion', {
        _key: 'conclusion',
        _context: 'AssessmentFinishDialogForCBManagers',
      });
    case 'confirmAssessmentResults':
      return t('I confirm the assessment results and the audit’s conclusion', {
        _key: 'confirmAssessmentResults',
        _context: 'AssessmentFinishDialogForCBManagers',
      });
    case 'decisionOnAuditResult':
      return t(
        'Please make the decision on audit result. After confirmation the assessment will be shared with SMK.',
        {
          _key: 'decisionOnAuditResult',
          _context: 'AssessmentFinishDialogForCBManagers',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentFinishDialogForFarmManagersStrings = (
  key: AssessmentFinishDialogForFarmManagersStringsKeyType,
): string => {
  switch (key) {
    case 'assessmentFinishDialogCompleteDescriptionForFarmManagers':
      return t('If you have answered all questions please click ', {
        _key: 'assessmentFinishDialogCompleteDescriptionForFarmManagers',
        _context: 'AssessmentFinishDialogForFarmManagers',
      });
    case 'assessmentFinishDialogContinueLaterDescriptionForFarmManagers':
      return t('If you are not finished, click ', {
        _key: 'assessmentFinishDialogContinueLaterDescriptionForFarmManagers',
        _context: 'AssessmentFinishDialogForFarmManagers',
      });
    case 'assessmentFinishDialogDescriptionForFarmManagers':
      return t(
        ' During the audit, you will receive a request from the auditor to grant them access to the data associated with this self assessment. This includes details of cultivations, answers to starting questions, and all attached evidence.',
        {
          _key: 'assessmentFinishDialogDescriptionForFarmManagers',
          _context: 'AssessmentFinishDialogForFarmManagers',
        },
      );
    case 'assessmentFinishDialogSubtitleForFarmManagers':
      return t(
        ' All your progress is saved, and you can easily find and continue your self assessment later from the Overview or Self Assessments page.',
        {
          _key: 'assessmentFinishDialogSubtitleForFarmManagers',
          _context: 'AssessmentFinishDialogForFarmManagers',
        },
      );
    case 'continueLater':
      return t('Continue later', {
        _key: 'continueLater',
        _context: 'AssessmentFinishDialogForFarmManagers',
      });
    case 'selfAssessmentWasSaved':
      return t('Self assessment was saved', {
        _key: 'selfAssessmentWasSaved',
        _context: 'AssessmentFinishDialogForFarmManagers',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentFinishDialogForReviewersStrings = (
  key: AssessmentFinishDialogForReviewersStringsKeyType,
): string => {
  switch (key) {
    case 'approvalPending':
      return t('Approval pending', {
        _key: 'approvalPending',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'approve':
      return t('Approve', {
        _key: 'approve',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'assessmentFinishDialogApproveDescForReviewers':
      return t('Have you completed the review and no further review actions are required? Click ', {
        _key: 'assessmentFinishDialogApproveDescForReviewers',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'assessmentFinishDialogApprovePendingDescForReviewers':
      return t('Does your approval require further actions/feedback from the auditor? Click ', {
        _key: 'assessmentFinishDialogApprovePendingDescForReviewers',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'assessmentFinishDialogCompletedMsgForReviewers':
      return t('Thank you for reviewing the inspection! All the data was saved.', {
        _key: 'assessmentFinishDialogCompletedMsgForReviewers',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'assessmentFinishDialogContinueLaterDescForReviewers':
      return t(
        ' to continue later. This adds the link below to your clipboard so you can paste/share it with the auditor to notify them of your request.',
        {
          _key: 'assessmentFinishDialogContinueLaterDescForReviewers',
          _context: 'AssessmentFinishDialogForReviewers',
        },
      );
    case 'assessmentFinishDialogCopyLinkDescForReviewers':
      return t('You can also copy the link below before making a selection.', {
        _key: 'assessmentFinishDialogCopyLinkDescForReviewers',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    case 'assessmentFinishDialogNotifyDescForReviewers':
      return t(
        '. This adds the link below to your clipboard so you can share it with your CB manager to notify them of your progress.',
        {
          _key: 'assessmentFinishDialogNotifyDescForReviewers',
          _context: 'AssessmentFinishDialogForReviewers',
        },
      );
    case 'reviewCompleted':
      return t('Review completed', {
        _key: 'reviewCompleted',
        _context: 'AssessmentFinishDialogForReviewers',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentPreliminaryQuestionsConfirmDialogStrings = (
  key: AssessmentPreliminaryQuestionsConfirmDialogStringsKeyType,
): string => {
  switch (key) {
    case 'confirmAssessmentPreliminaryQuestions':
      return t('Confirm assessment preliminary questions', {
        _key: 'confirmAssessmentPreliminaryQuestions',
        _context: 'AssessmentPreliminaryQuestionsConfirmDialog',
      });
    case 'evidenceName':
      return t('Evidence name', {
        _key: 'evidenceName',
        _context: 'AssessmentPreliminaryQuestionsConfirmDialog',
      });
    case 'preliminaryQuestionConfirm':
      return t(
        'Your answers to the preliminary questions will cause some questions on the assessment to be hidden. This will also detach the evidence listed below from these questions. Please confirm that you wish to detach this evidence.',
        {
          _key: 'preliminaryQuestionConfirm',
          _context: 'AssessmentPreliminaryQuestionsConfirmDialog',
        },
      );
    case 'questionCode':
      return t('Question code', {
        _key: 'questionCode',
        _context: 'AssessmentPreliminaryQuestionsConfirmDialog',
      });
    case 'reject':
      return t('Reject', {
        _key: 'reject',
        _context: 'AssessmentPreliminaryQuestionsConfirmDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentPreliminaryQuestionsStepStrings = (
  key: AssessmentPreliminaryQuestionsStepStringsKeyType,
): string => {
  switch (key) {
    case 'preparatoryQuestions':
      return t('Preparatory questions', {
        _key: 'preparatoryQuestions',
        _context: 'AssessmentPreliminaryQuestionsStep',
      });
    case 'preparatoryQuestionsStepDescription':
      return t(
        'Answering these questions allows the system to automatically fill-in some of the answers on the checklist, letting you focus only on the parts that are relevant to your situation.',
        {
          _key: 'preparatoryQuestionsStepDescription',
          _context: 'AssessmentPreliminaryQuestionsStep',
        },
      );
    case 'preparatoryQuestionsPageDescriptionForAuditor':
      return t(
        'Answering these questions allows the system to filter-out control points that are not relevant to this inspection during the Checklist step. If you would prefer to view the checklist in an unaltered state you can skip this step.',
        {
          _key: 'preparatoryQuestionsPageDescriptionForAuditor',
          _context: 'AssessmentPreliminaryQuestionsStep',
        },
      );
    case 'savePreliminaryQuestionsFail':
      return t('Failed to save answers to preliminary questions.', {
        _key: 'savePreliminaryQuestionsFail',
        _context: 'AssessmentPreliminaryQuestionsStep',
      });
    case 'savePreliminaryQuestionsSuccess':
      return t('Successfully saved answers to preliminary questions.', {
        _key: 'savePreliminaryQuestionsSuccess',
        _context: 'AssessmentPreliminaryQuestionsStep',
      });
    case 'showDetailedQuestionDescription':
      return t('Show detailed question description', {
        _key: 'showDetailedQuestionDescription',
        _context: 'AssessmentPreliminaryQuestionsStep',
      });
    case 'startingQuestions':
      return t('Starting questions', {
        _key: 'startingQuestions',
        _context: 'AssessmentPreliminaryQuestionsStep',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentReportStepStrings = (key: AssessmentReportStepStringsKeyType): string => {
  switch (key) {
    case 'allThemes':
      return t('All themes', {
        _key: 'allThemes',
        _context: 'AssessmentReportStep',
      });
    case 'backToTheDashboard':
      return t('Back to the dashboard', {
        _key: 'backToTheDashboard',
        _context: 'AssessmentReportStep',
      });
    case 'bonusPointsDetalization':
      return t('Bonus points detalization', {
        _key: 'bonusPointsDetalization',
        _context: 'AssessmentReportStep',
      });
    case 'bonusPointsForOptionalMeasures':
      return t('Bonus points for optional measures', {
        _key: 'bonusPointsForOptionalMeasures',
        _context: 'AssessmentReportStep',
      });
    case 'bonusPointsForOptionalMeasuresDetail':
      return t('(for Crop protection, Biodiversity and Soil quality)', {
        _key: 'bonusPointsForOptionalMeasuresDetail',
        _context: 'AssessmentReportStep',
      });
    case 'bonusPointsFromOtherCategories':
      return t('Bonus points from other categories ', {
        _key: 'bonusPointsFromOtherCategories',
        _context: 'AssessmentReportStep',
      });
    case 'bonusPointsFromOtherCategoriesDetail':
      return t('(exc. Packaging)', {
        _key: 'bonusPointsFromOtherCategoriesDetail',
        _context: 'AssessmentReportStep',
      });
    case 'complianceIndication':
      return t('Compliance indication', {
        _key: 'complianceIndication',
        _context: 'AssessmentReportStep',
      });
    case 'complianceReport':
      return t('Compliance report', {
        _key: 'complianceReport',
        _context: 'AssessmentReportStep',
      });
    case 'currentBalance':
      return t('Current balance ', {
        _key: 'currentBalance',
        _context: 'AssessmentReportStep',
      });
    case 'documentsExport':
      return t('Documents export', {
        _key: 'documentsExport',
        _context: 'AssessmentReportStep',
      });
    case 'downloadAssessmentExportFileFail':
      return t('Failed to download file.', {
        _key: 'downloadAssessmentExportFileFail',
        _context: 'AssessmentReportStep',
      });
    case 'exportAssessmentAndEvidence':
      return t('Export assessment & evidence', {
        _key: 'exportAssessmentAndEvidence',
        _context: 'AssessmentReportStep',
      });
    case 'exportAssessmentDetails':
      return t('Export assessment details', {
        _key: 'exportAssessmentDetails',
        _context: 'AssessmentReportStep',
      });
    case 'listOfNonCompliances':
      return t('List of non-compliances', {
        _key: 'listOfNonCompliances',
        _context: 'AssessmentReportStep',
      });
    case 'malusBonusPoints':
      return t('Malus/bonus points', {
        _key: 'malusBonusPoints',
        _context: 'AssessmentReportStep',
      });
    case 'minimalBonusPointsToScore':
      return t('Minimal bonus points to score', {
        _key: 'minimalBonusPointsToScore',
        _context: 'AssessmentReportStep',
      });
    case 'numberOf':
      return t('Number of', {
        _key: 'numberOf',
        _context: 'AssessmentReportStep',
      });
    case 'preparingDocuments':
      return t('Preparing documents', {
        _key: 'preparingDocuments',
        _context: 'AssessmentReportStep',
      });
    case 'requirements':
      return t('requirements', {
        _key: 'requirements',
        _context: 'AssessmentReportStep',
      });
    case 'sharingAssessmentWithAuditor':
      return t('Sharing assessment with auditor', {
        _key: 'sharingAssessmentWithAuditor',
        _context: 'AssessmentReportStep',
      });
    case 'tableColorDesc':
      return t(
        ' Field color for these columns indicates compliance status: green - compliant, red - non-compliant',
        {
          _key: 'tableColorDesc',
          _context: 'AssessmentReportStep',
        },
      );
    case 'totalMalusPoints':
      return t('Total malus points to compensate (incl. 2.11&7.3)', {
        _key: 'totalMalusPointsToCompensate',
        _context: 'AssessmentReportStep',
      });
    case 'inspectionTimeTracking':
      return t('Inspection time tracking', {
        _key: 'inspectionTimeTracking',
        _context: 'AssessmentReportStep',
      });
    case 'totalAuditDuration':
      return t('Total audit duration', {
        _key: 'totalAuditDuration',
        _context: 'AssessmentReportStep',
      });
    case 'placeholderHHMM':
      return t('hh:mm', {
        _key: 'placeholderHHMM',
        _context: 'AssessmentReportStep',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentShareDialogStrings = (key: AssessmentShareDialogStringsKeyType): string => {
  switch (key) {
    case 'assessmentSharing':
      return t('Assessment sharing', {
        _key: 'assessmentSharing',
        _context: 'AssessmentShareDialog',
      });
    case 'inspectionSharing':
      return t('Inspection sharing', {
        _key: 'inspectionSharing',
        _context: 'AssessmentShareDialog',
      });
    case 'shareAssessmentToColleagues':
      return t('Use the link below to share this assessment with your colleagues:', {
        _key: 'shareAssessmentToColleagues',
        _context: 'AssessmentShareDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentShareWithAuditorDialogStrings = (
  key: AssessmentShareWithAuditorDialogStringsKeyType,
  params?: { assessmentName?: string; standardName?: string },
): string => {
  switch (key) {
    case 'afterSharingWarningInfo':
      return t(
        'After sharing, you won’t be able to restrict access to it and it will be available to auditor for 4 weeks.',
        {
          _key: 'afterSharingWarningInfo',
          _context: 'AssessmentShareWithAuditorDialog',
        },
      );
    case 'send':
      return t('Send', {
        _key: 'send',
        _context: 'AssessmentShareWithAuditorDialog',
      });
    case 'shareAssessmentWithTheAuditor':
      return t('Share {standardName} / {assessmentName}', {
        _key: 'shareAssessmentWithTheAuditor',
        _context: 'AssessmentShareWithAuditorDialog',
        ...params,
      });
    case 'shareAssessmentWithTheAuditorDesc':
      return t(
        'This will allow auditors from your Certification organisation to view the report with all the comments, recorded non-conformities and attached documents. Please be aware that sharing your self assessment in advance does not affect the audit duration.',
        {
          _key: 'shareAssessmentWithTheAuditorDesc',
          _context: 'AssessmentShareWithAuditorDialog',
        },
      );
    case 'shareAssessmentWithTheAuditorFail':
      return t('Failed to share assessment with auditor.', {
        _key: 'shareAssessmentWithTheAuditorFail',
        _context: 'AssessmentShareWithAuditorDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentShareWithAuditorResultDialogStrings = (
  key: AssessmentShareWithAuditorResultDialogStringsKeyType,
): string => {
  switch (key) {
    case 'assessmentSharingIsInProgress':
      return t('Assessment sharing is in progress', {
        _key: 'assessmentSharingIsInProgress',
        _context: 'AssessmentShareWithAuditorResultDialog',
      });
    case 'changesWillNotBeReflected':
      return t(
        'Please note, that changes made to the assessment after this point will not be reflected in the version sent to the auditors.',
        {
          _key: 'changesWillNotBeReflected',
          _context: 'AssessmentShareWithAuditorResultDialog',
        },
      );
    case 'youWillReceiveEmailNotification':
      return t(
        'You will receive email notification once the assessment is shared with your Certification organisation.',
        {
          _key: 'youWillReceiveEmailNotification',
          _context: 'AssessmentShareWithAuditorResultDialog',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const changePasswordFormStrings = (key: ChangePasswordFormStringsKeyType): string => {
  switch (key) {
    case 'changePasswordFail':
      return t('Failed to change password!', {
        _key: 'changePasswordFail',
        _context: 'ChangePasswordForm',
      });
    case 'changePasswordSuccess':
      return t('Successfully changed password!', {
        _key: 'changePasswordSuccess',
        _context: 'ChangePasswordForm',
      });
    case 'confirmNewPassword':
      return t('Confirm new password', {
        _key: 'confirmNewPassword',
        _context: 'ChangePasswordForm',
      });
    case 'currentPassword':
      return t('Current password', {
        _key: 'currentPassword',
        _context: 'ChangePasswordForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const changeTemporaryPasswordPageStrings = (
  key: ChangeTemporaryPasswordPageStringsKeyType,
): string => {
  switch (key) {
    case 'changeTemporaryPasswordSuccess':
      return t('Your account has been set up and your password has been updated successfully!', {
        _key: 'changeTemporaryPasswordSuccess',
        _context: 'ChangeTemporaryPasswordPage',
      });
    case 'completeYourProfile':
      return t('Complete your profile', {
        _key: 'completeYourProfile',
        _context: 'ChangeTemporaryPasswordPage',
      });
    case 'newPasswordRepeat':
      return t('New password (repeat)', {
        _key: 'newPasswordRepeat',
        _context: 'ChangeTemporaryPasswordPage',
      });
    case 'setUpAccount':
      return t('Set up account', {
        _key: 'setUpAccount',
        _context: 'ChangeTemporaryPasswordPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const checkboxCellStrings = (key: CheckboxCellStringsKeyType): string => {
  switch (key) {
    case 'excluded':
      return t('Excluded', {
        _key: 'excluded',
        _context: 'CheckboxCell',
      });
    case 'included':
      return t('Included', {
        _key: 'included',
        _context: 'CheckboxCell',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const commentStrings = (key: CommentStringsKeyType): string => {
  switch (key) {
    case 'deleteComment':
      return t('Delete comment', {
        _key: 'deleteComment',
        _context: 'Comment',
      });
    case 'deleteCommentFail':
      return t('Failed to delete comment.', {
        _key: 'deleteCommentFail',
        _context: 'Comment',
      });
    case 'deleteCommentSuccess':
      return t('Successfully deleted comment.', {
        _key: 'deleteCommentSuccess',
        _context: 'Comment',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointAnswerStrings = (
  key: ControlPointAnswerStringsKeyType,
  params?: { controlPointCode?: string; productionTechniqueName?: string },
): string => {
  switch (key) {
    case 'createAnswerChangeToQuestionFail':
      return t('Failed to create an answer regarding {controlPointCode}.', {
        _key: 'createAnswerChangeToQuestionFail',
        _context: 'ControlPointAnswer',
        ...params,
      });
    case 'createAnswerChangeToQuestionSuccess':
      return t('Successfully created an answer regarding question {controlPointCode}.', {
        _key: 'createAnswerChangeToQuestionSuccess',
        _context: 'ControlPointAnswer',
        ...params,
      });
    case 'productionTechniqueName':
      return t('{productionTechniqueName} cultivations', {
        _key: 'productionTechniqueName',
        _context: 'ControlPointAnswer',
        ...params,
      });
    case 'updateAnswerChangeToQuestionFail':
      return t('Failed to save changes regarding the answer to question {controlPointCode}.', {
        _key: 'updateAnswerChangeToQuestionFail',
        _context: 'ControlPointAnswer',
        ...params,
      });
    case 'updateAnswerChangeToQuestionSuccess':
      return t('Successfully saved changes regarding the answer to question {controlPointCode}.', {
        _key: 'updateAnswerChangeToQuestionSuccess',
        _context: 'ControlPointAnswer',
        ...params,
      });
    case 'verifyAnswersByCultivations':
      return t('VERIFY ANSWERS BY CULTIVATIONS', {
        _key: 'verifyAnswersByCultivations',
        _context: 'ControlPointAnswer',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointAnsweredByTriggerChipStrings = (
  key: ControlPointAnsweredByTriggerChipStringsKeyType,
  params?: { triggerAnswer?: string; triggerName?: string | null },
): string => {
  switch (key) {
    case 'autoFilledAnswer':
      return t(
        'This control point was filled in based on your answer to the question {triggerName}',
        {
          _key: 'autoFilledAnswer',
          _context: 'ControlPointAnsweredByTriggerChip',
          ...params,
        },
      );
    case 'autoFilledAnswerWithAnswerToControlPoint':
      return t(
        'This control point was filled in based on your answer of "{triggerAnswer}" to the control point: {triggerName}',
        {
          _key: 'autoFilledAnswerWithAnswerToControlPoint',
          _context: 'ControlPointAnsweredByTriggerChip',
          ...params,
        },
      );
    case 'autoFilledAnswerWithAnswerToPreliminaryQuestion':
      return t(
        'This control point was filled in based on your answer of "{triggerAnswer}" to the preliminary question: {triggerName}',
        {
          _key: 'autoFilledAnswerWithAnswerToPreliminaryQuestion',
          _context: 'ControlPointAnsweredByTriggerChip',
          ...params,
        },
      );
    case 'autoFilledAnswerWithEvidence':
      return t('This question was automatically filled in based on attached documentation.', {
        _key: 'autoFilledAnswerWithEvidence',
        _context: 'ControlPointAnsweredByTriggerChip',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointAnswerPointsStrings = (
  key: ControlPointAnswerPointsStringsKeyType,
): string => {
  switch (key) {
    case 'noAnswer':
      return t('No answer', {
        _key: 'noAnswer',
        _context: 'ControlPointAnswerPoints',
      });
    case 'points':
      return t('Points', {
        _key: 'points',
        _context: 'ControlPointAnswerPoints',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointDropdownAnswerStrings = (
  key: ControlPointDropdownAnswerStringsKeyType,
): string => {
  switch (key) {
    case 'selectOption':
      return t('Select option', {
        _key: 'selectOption',
        _context: 'ControlPointDropdownAnswer',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointEvidenceDialogStrings = (
  key: ControlPointEvidenceDialogStringsKeyType,
  params?: { questionCode?: string },
): string => {
  switch (key) {
    case 'allDocuments':
      return t('All documents', {
        _key: 'allDocuments',
        _context: 'ControlPointEvidenceDialog',
      });
    case 'attachDocumentForQuestionCode':
      return t('Attach document for {questionCode}', {
        _key: 'attachDocumentForQuestionCode',
        _context: 'ControlPointEvidenceDialog',
        ...params,
      });
    case 'suggestedDocuments':
      return t('Suggested documents', {
        _key: 'suggestedDocuments',
        _context: 'ControlPointEvidenceDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointGuidanceDialogStrings = (
  key: ControlPointGuidanceDialogStringsKeyType,
  params?: { questionCode?: string },
): string => {
  switch (key) {
    case 'questionDetailsForQuestionCode':
      return t('Question details for {questionCode}', {
        _key: 'questionDetailsForQuestionCode',
        _context: 'ControlPointGuidanceDialog',
        ...params,
      });
    case 'questionForQuestionCode':
      return t('Question {questionCode}', {
        _key: 'questionForQuestionCode',
        _context: 'ControlPointGuidanceDialog',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointGuidanceTabStrings = (
  key: ControlPointGuidanceTabStringsKeyType,
): string => {
  switch (key) {
    case 'guidance':
      return t('Guidance', {
        _key: 'guidance',
        _context: 'ControlPointGuidanceTab',
      });
    case 'justificationGuideline':
      return t('Justification guideline', {
        _key: 'justificationGuideline',
        _context: 'ControlPointGuidanceTab',
      });
    case 'noCriteriaOrGuidanceAdded':
      return t('No criteria or guidance added for this question.', {
        _key: 'noCriteriaOrGuidanceAdded',
        _context: 'ControlPointGuidanceTab',
      });
    case 'verificationMethod':
      return t('Verification method', {
        _key: 'verificationMethod',
        _context: 'ControlPointGuidanceTab',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointHeaderStrings = (key: ControlPointHeaderStringsKeyType): string => {
  switch (key) {
    case 'autofilled':
      return t('Autofilled', {
        _key: 'autofilled',
        _context: 'ControlPointHeader',
      });
    case 'cultivationLevel':
      return t('Cultivation level', {
        _key: 'cultivationLevel',
        _context: 'ControlPointHeader',
      });
    case 'farmLevel':
      return t('Farm level', {
        _key: 'farmLevel',
        _context: 'ControlPointHeader',
      });
    case 'flagControlPoint':
      return t('Flag control point', {
        _key: 'flagControlPoint',
        _context: 'ControlPointHeader',
      });
    case 'attachDocument':
      return t('Attach document', {
        _key: 'attachDocument',
        _context: 'ControlPointHeader',
      });
    case 'mandatoryDocumentSharedWithStandardOwner':
      return t('Mandatory document shared with standard owner', {
        _key: 'mandatoryDocumentSharedWithStandardOwner',
        _context: 'ControlPointHeader',
      });
    case 'mandatoryEvidenceSharedWithStandardOwner':
      return t('Mandatory evidence shared with standard owner', {
        _key: 'mandatoryEvidenceSharedWithStandardOwner',
        _context: 'ControlPointHeader',
      });
    case 'plotLevel':
      return t('Plot level', {
        _key: 'plotLevel',
        _context: 'ControlPointHeader',
      });
    case 'unflagControlPoint':
      return t('Unflag control point', {
        _key: 'unflagControlPoint',
        _context: 'ControlPointHeader',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointHeaderKebabMenuStrings = (
  key: ControlPointHeaderKebabMenuStringsKeyType,
): string => {
  switch (key) {
    case 'addComment':
      return t('Add comment', {
        _key: 'addComment',
        _context: 'ControlPointHeaderKebabMenu',
      });
    case 'questionDetails':
      return t('Question details', {
        _key: 'questionDetails',
        _context: 'ControlPointHeaderKebabMenu',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointStrings = (key: ControlPointStringsKeyType): string => {
  switch (key) {
    case 'attachDocumentFail':
      return t('Failed to attach document.', {
        _key: 'attachDocumentFail',
        _context: 'ControlPoint',
      });
    case 'attachDocumentSuccess':
      return t('Document was attached.', {
        _key: 'attachDocumentSuccess',
        _context: 'ControlPoint',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const controlPointUploadedEvidenceStrings = (
  key: ControlPointUploadedEvidenceStringsKeyType,
  params?: { existingEvidencesLength?: number },
): string => {
  switch (key) {
    case 'deleteEvidence':
      return t('Delete evidence', {
        _key: 'deleteEvidence',
        _context: 'ControlPointUploadedEvidence',
      });
    case 'deleteEvidenceFail':
      return t('Failed to delete evidence.', {
        _key: 'deleteEvidenceFail',
        _context: 'ControlPointUploadedEvidence',
      });
    case 'deleteEvidenceSuccess':
      return t('Successfully deleted evidence.', {
        _key: 'deleteEvidenceSuccess',
        _context: 'ControlPointUploadedEvidence',
      });
    case 'showAllExistingEvidences':
      return t('Show all {existingEvidencesLength} files', {
        _key: 'showAllExistingEvidences',
        _context: 'ControlPointUploadedEvidence',
        ...params,
      });
    case 'showLess':
      return t('Show less', {
        _key: 'showLess',
        _context: 'ControlPointUploadedEvidence',
      });
    case 'uploadedEvidence':
      return t('Uploaded evidence', {
        _key: 'uploadedEvidence',
        _context: 'ControlPointUploadedEvidence',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const correctiveActionStrings = (key: CorrectiveActionStringsKeyType): string => {
  switch (key) {
    case 'correctiveActions':
      return t('Corrective actions', {
        _key: 'correctiveActions',
        _context: 'CorrectiveAction',
      });
    case 'deleteCorrectiveAction':
      return t('Delete corrective action', {
        _key: 'deleteCorrectiveAction',
        _context: 'CorrectiveAction',
      });
    case 'deleteCorrectiveActionFail':
      return t('Failed to delete corrective action.', {
        _key: 'deleteCorrectiveActionFail',
        _context: 'CorrectiveAction',
      });
    case 'deleteCorrectiveActionSuccess':
      return t('Successfully deleted corrective action.', {
        _key: 'deleteCorrectiveActionSuccess',
        _context: 'CorrectiveAction',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const createEvidenceDialogStrings = (
  key: CreateEvidenceDialogStringsKeyType,
  params?: { name?: string },
): string => {
  switch (key) {
    case 'creatingEvidenceTitle':
      return t('Creating {name}', {
        _key: 'creatingEvidenceTitle',
        _context: 'CreateEvidenceDialog',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const createEvidenceSaveDialogStrings = (
  key: CreateEvidenceSaveDialogStringsKeyType,
  params?: { evidence_name?: string; control_point_name?: string },
): string => {
  switch (key) {
    case 'createAndAttachedDocumentFailMessageForChecklistPage':
      return t('Failed to save document "{evidence_name}" for question "{control_point_name}"', {
        _key: 'createAndAttachedDocumentFailMessageForChecklistPage',
        _context: 'CreateEvidenceSaveDialog',
        ...params,
      });
    case 'createAndAttachedDocumentSuccessMessageForChecklistPage':
      return t(
        'Document "{evidence_name}" was saved and attached to question "{control_point_name}"',
        {
          _key: 'createAndAttachedDocumentSuccessMessageForChecklistPage',
          _context: 'CreateEvidenceSaveDialog',
          ...params,
        },
      );
    case 'createDocumentFailWithName':
      return t('Failed to save document "{evidence_name}"', {
        _key: 'createDocumentFailWithName',
        _context: 'CreateEvidenceSaveDialog',
        ...params,
      });
    case 'createDocumentSuccessWithName':
      return t('Document "{evidence_name}" was saved.', {
        _key: 'createDocumentSuccessWithName',
        _context: 'CreateEvidenceSaveDialog',
        ...params,
      });
    case 'confirmEvidenceInformationProvided':
      return t(
        'By checking this box, I confirm that the information provided is accurate and complete',
        {
          _key: 'confirmEvidenceInformationProvided',
          _context: 'CreateEvidenceSaveDialog',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardBlockRowStrings = (key: DashboardBlockRowStringsKeyType): string => {
  switch (key) {
    case 'view':
      return t('View', {
        _key: 'view',
        _context: 'DashboardBlockRow',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardOrganizationBlockStrings = (
  key: DashboardOrganizationBlockStringsKeyType,
): string => {
  switch (key) {
    case 'cultivationsAdded':
      return t('Cultivations added', {
        _key: 'cultivationsAdded',
        _context: 'DashboardOrganizationBlock',
      });
    case 'editProfileDetails':
      return t('Edit profile details', {
        _key: 'editProfileDetails',
        _context: 'DashboardOrganizationBlock',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardPageStrings = (key: DashboardPageStringsKeyType): string => {
  switch (key) {
    case 'allInspections':
      return t('All inspections', {
        _key: 'allInspections',
        _context: 'DashboardPage',
      });
    case 'allSelfAssessments':
      return t('All self assessments', {
        _key: 'allSelfAssessments',
        _context: 'DashboardPage',
      });
    case 'bannerTitle':
      return t('You\'re using the Beta version of the Pro Plan', {
        _key: 'bannerTitle',
        _context: 'DashboardPage',
      });
    case 'bannerSubTitle':
      return t(
        'All features are available for you to explore! Starting January 2025, you\'ll able to choose from three new plans tailored to your needs. More details coming soon.',
        {
          _key: 'bannerSubTitle',
          _context: 'DashboardPage',
        },
      );
    case 'confirmedInspections':
      return t('Confirmed inspections', {
        _key: 'confirmedInspections',
        _context: 'DashboardPage',
      });
    case 'inProgressInspections':
      return t('In progress inspections', {
        _key: 'inProgressInspections',
        _context: 'DashboardPage',
      });
    case 'inProgressSelfAssessments':
      return t('In progress self assessments', {
        _key: 'inProgressSelfAssessments',
        _context: 'DashboardPage',
      });
    case 'myOverview':
      return t('My overview', {
        _key: 'myOverview',
        _context: 'DashboardPage',
      });
    case 'newInspectionsToConfirm':
      return t('New inspections to confirm', {
        _key: 'newInspectionsToConfirm',
        _context: 'DashboardPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardRequestPageStrings = (key: DashboardRequestPageStringsKeyType): string => {
  switch (key) {
    case 'createdAt':
      return t('Created at', {
        _key: 'createdAt',
        _context: 'DashboardRequestPage',
      });
    case 'createdBy':
      return t('Created by', {
        _key: 'createdBy',
        _context: 'DashboardRequestPage',
      });
    case 'requestTableNoRowsMsg':
      return t(
        'Track relevant statuses of the requests you have sent to access farm managers\' self assessment data here.',
        {
          _key: 'requestTableNoRowsMsg',
          _context: 'DashboardRequestPage',
        },
      );
    case 'requestTableNoRowsMsgForFarmManagers':
      return t(
        'Track relevant statuses of the requests from auditors to access your self assessment data here.',
        {
          _key: 'requestTableNoRowsMsgForFarmManagers',
          _context: 'DashboardRequestPage',
        },
      );
    case 'request':
      return t('Request', {
        _key: 'request',
        _context: 'DashboardRequestPage',
      });
    case 'sentTo':
      return t('Sent to', {
        _key: 'sentTo',
        _context: 'DashboardRequestPage',
      });
    case 'statusChangedAt':
      return t('Status changed at', {
        _key: 'statusChangedAt',
        _context: 'DashboardRequestPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardRequestBlockStrings = (key: DashboardRequestBlockStringsKeyType): string => {
  switch (key) {
    case 'allRequests':
      return t('All requests', {
        _key: 'allRequests',
        _context: 'DashboardRequestBlock',
      });
    case 'approvedRequests':
      return t('Approved requests', {
        _key: 'approvedRequests',
        _context: 'DashboardRequestBlock',
      });
    case 'functionalityForPlanetProof':
      return t('Functionality is available for PlanetProof certificate', {
        _key: 'functionalityForPlanetProof',
        _context: 'DashboardRequestBlock',
      });
    case 'pendingRequests':
      return t('Pending requests', {
        _key: 'pendingRequests',
        _context: 'DashboardRequestBlock',
      });
    case 'requestsFromAuditors':
      return t('Requests from auditors', {
        _key: 'requestsFromAuditors',
        _context: 'DashboardRequestBlock',
      });
    case 'resolvedRequests':
      return t('Resolved requests', {
        _key: 'resolvedRequests',
        _context: 'DashboardRequestBlock',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const dashboardUserBlockStrings = (key: DashboardUserBlockStringsKeyType): string => {
  switch (key) {
    case 'addNewUser':
      return t('Add new user', {
        _key: 'addNewUser',
        _context: 'DashboardUserBlock',
      });
    case 'addingUserToOrganization':
      return t('Adding user to organisation', {
        _key: 'addingUserToOrganization',
        _context: 'DashboardUserBlock',
      });
    case 'totalUser':
      return t('Total user', {
        _key: 'totalUser',
        _context: 'DashboardUserBlock',
      });
    case 'yourOrganization':
      return t('Your organisation', {
        _key: 'yourOrganization',
        _context: 'DashboardUserBlock',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const editCorrectiveActionDialogStrings = (
  key: EditCorrectiveActionDialogStringsKeyType,
): string => {
  switch (key) {
    case 'correctiveActionDescription':
      return t('Corrective action description', {
        _key: 'correctiveActionDescription',
        _context: 'EditCorrectiveActionDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const editNonConformityDialogStrings = (
  key: EditNonConformityDialogStringsKeyType,
): string => {
  switch (key) {
    case 'deadlineDate':
      return t('Deadline date', {
        _key: 'deadlineDate',
        _context: 'EditNonConformityDialog',
      });
    case 'nonConformityDescription':
      return t('Non-conformity description', {
        _key: 'nonConformityDescription',
        _context: 'EditNonConformityDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const EvidenceTabsPageStrings = (key: EvidenceTabsPageStringsKeyType): string => {
  switch (key) {
    case 'validDocuments':
      return t('Valid documents', {
        _key: 'validDocuments',
        _context: 'EvidenceTabsPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceAttachToggleStrings = (
  key: EvidenceAttachToggleStringsKeyType,
  params?: { evidenceName?: string },
): string => {
  switch (key) {
    case 'attachEvidenceFail':
      return t('Failed to attach {evidenceName}.', {
        _key: 'attachEvidenceFail',
        _context: 'EvidenceAttachToggle',
        ...params,
      });
    case 'attachEvidenceSuccess':
      return t('Successfully attached {evidenceName}.', {
        _key: 'attachEvidenceSuccess',
        _context: 'EvidenceAttachToggle',
        ...params,
      });
    case 'detach':
      return t('Detach', {
        _key: 'detach',
        _context: 'EvidenceAttachToggle',
      });
    case 'detachEvidenceFail':
      return t('Failed to detach {evidenceName}.', {
        _key: 'detachEvidenceFail',
        _context: 'EvidenceAttachToggle',
        ...params,
      });
    case 'detachEvidenceSuccess':
      return t('Successfully detached {evidenceName}.', {
        _key: 'detachEvidenceSuccess',
        _context: 'EvidenceAttachToggle',
        ...params,
      });
    case 'loadingButtonText':
      return t('Loading...', {
        _key: 'loadingButtonText',
        _context: 'EvidenceAttachToggle',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceControlPointTableStrings = (
  key: EvidenceControlPointTableStringsKeyType,
): string => {
  switch (key) {
    case 'cp':
      return t('CP', {
        _key: 'cp',
        _context: 'EvidenceControlPointTable',
      });
    case 'description':
      return t('Description', {
        _key: 'description',
        _context: 'EvidenceControlPointTable',
      });
    case 'evidenceWasAttachedTooltip':
      return t('Evidence was attached to this control point in the farmer’s self-assessment', {
        _key: 'evidenceWasAttachedTooltip',
        _context: 'EvidenceControlPointTable',
      });
    case 'noControlPointsToDisplay':
      return t(
        'No control points to display. Try refreshing the page.\nIf the problem persists, contact Agriplace user support.',
        {
          _key: 'noControlPointsToDisplay',
          _context: 'EvidenceControlPointTable',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceDeleteDialogStrings = (
  key: EvidenceDeleteDialogStringsKeyType,
  params?: { evidenceName?: string; name?: string },
): string => {
  switch (key) {
    case 'backendDeleteSuccessMessage':
      return t('Successfully deleted {name}.', {
        _key: 'backendDeleteSuccessMessage',
        _context: 'EvidenceDeleteDialog',
        ...params,
      });
    case 'deleteEvidenceDialogDescription':
      return t('"{evidenceName}" will be deleted and it will not be possible to recover it.', {
        _key: 'deleteEvidenceDialogDescription',
        _context: 'EvidenceDeleteDialog',
        ...params,
      });
    case 'deleteTheDocument':
      return t('Delete the document?', {
        _key: 'deleteTheDocument',
        _context: 'EvidenceDeleteDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceEditDialogStrings = (key: EvidenceEditDialogStringsKeyType): string => {
  switch (key) {
    case 'invalidFileName':
      return t('File name must not include special characters: \\:/*?"<>|', {
        _key: 'invalidFileName',
        _context: 'EvidenceEditDialog',
      });
    case 'pleaseAddDocumentTitle':
      return t('Please add document title', {
        _key: 'pleaseAddDocumentTitle',
        _context: 'EvidenceEditDialog',
      });
    case 'pleaseAddExpiryDateForTheUploadedDocument':
      return t('Please add expiry date for the uploaded document', {
        _key: 'pleaseAddExpiryDateForTheUploadedDocument',
        _context: 'EvidenceEditDialog',
      });
    case 'pleaseSelectDocument':
      return t('Please select the document to upload', {
        _key: 'pleaseSelectDocument',
        _context: 'EvidenceEditDialog',
      });
    case 'uploadEditEvidence':
      return t('Upload/Edit evidence', {
        _key: 'uploadEditEvidence',
        _context: 'EvidenceEditDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceExpiredDocumentsListStrings = (
  key: EvidenceExpiredDocumentsListStringsKeyType,
): string => {
  switch (key) {
    case 'archive':
      return t('Archive', {
        _key: 'archive',
        _context: 'EvidenceExpiredDocumentsList',
      });
    case 'archivedDocuments':
      return t('Archived documents', {
        _key: 'archivedDocuments',
        _context: 'EvidenceExpiredDocumentsList',
      });
    case 'archiveEvidenceFail':
      return t('Failed to archive evidence.', {
        _key: 'archiveEvidenceFail',
        _context: 'EvidenceExpiredDocumentsList',
      });
    case 'archiveEvidenceSuccess':
      return t('Successfully archived evidence.', {
        _key: 'archiveEvidenceSuccess',
        _context: 'EvidenceExpiredDocumentsList',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceFormsDialogStrings = (
  key: EvidenceFormsDialogStringsKeyType,
  params?: { name?: string },
): string => {
  switch (key) {
    case 'createDraftDocumentFailWithName':
      return t('Failed to create draft document with evidence form "{name}"', {
        _key: 'createDraftDocumentFailWithName',
        _context: 'EvidenceFormsDialog',
        ...params,
      });
    case 'createDraftDocumentSuccessWithName':
      return t('Document draft was created with evidence form "{name}"', {
        _key: 'createDraftDocumentSuccessWithName',
        _context: 'EvidenceFormsDialog',
        ...params,
      });
    case 'noEvidenceFormToDisplay':
      return t(
        'No evidence form to display. Try refreshing the page.\nIf the problem persists, contact Agriplace user support.',
        {
          _key: 'noEvidenceFormToDisplay',
          _context: 'EvidenceFormsDialog',
        },
      );
    case 'publishedDate':
      return t('Published date', {
        _key: 'publishedDate',
        _context: 'EvidenceFormsDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceReviewControlPointStrings = (
  key: EvidenceReviewControlPointStringsKeyType,
): string => {
  switch (key) {
    case 'readMore':
      return t('Read more', {
        _key: 'readMore',
        _context: 'EvidenceReviewControlPoint',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceReviewDrawerStrings = (
  key: EvidenceReviewDrawerStringsKeyType,
  params?: {
    evidenceName?: string;
  },
): string => {
  switch (key) {
    case 'bulkDetachEvidenceFail':
      return t('Failed to detach evidence from all control points.', {
        _key: 'bulkDetachEvidenceFail',
        _context: 'EvidenceReviewDrawer',
      });
    case 'bulkDetachEvidenceSuccess':
      return t('Successfully detached evidence from all control points.', {
        _key: 'bulkDetachEvidenceSuccess',
        _context: 'EvidenceReviewDrawer',
      });
    case 'detachFromAll':
      return t('Detach from all', {
        _key: 'detachFromAll',
        _context: 'EvidenceReviewDrawer',
      });
    case 'nextDocument':
      return t('Next document', {
        _key: 'nextDocument',
        _context: 'EvidenceReviewDrawer',
      });
    case 'nextEvidence':
      return t('Next evidence', {
        _key: 'nextEvidence',
        _context: 'EvidenceReviewDrawer',
      });
    case 'previewEvidenceAndManageAttachments':
      return t('Preview {evidenceName} and manage attachments', {
        _key: 'previewEvidenceAndManageAttachments',
        _context: 'EvidenceReviewDrawer',
        ...params,
      });
    case 'reviewTheEvidenceAndControlPoints':
      return t(
        'Review the evidence and control points it was attached to within the self assessment.\nConfirm this selection or/and attach to other control points. You can use the Filters button below to easily find specific control points via their control point number, or description.',
        {
          _key: 'reviewTheEvidenceAndControlPoints',
          _context: 'EvidenceReviewDrawer',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceTableStrings = (
  key: EvidenceTableStringsKeyType,
  params?: {
    categoryName?: string;
    evidenceType?: string;
    questionCount?: string;
  },
): string => {
  switch (key) {
    case 'autoAttachedTooltipText':
      return t(
        'This document was auto-attached based on its relevance from previous assessments. Click button on the right to preview and manage attachments.',
        {
          _key: 'autoAttachedTooltipText',
          _context: 'EvidenceTable',
        },
      );
    case 'createWithEvidenceForm':
      return t('Create with evidence form', {
        _key: 'createWithEvidenceForm',
        _context: 'EvidenceTable',
      });
    case 'manageAttachment':
      return t('Manage attachment', {
        _key: 'manageAttachment',
        _context: 'EvidenceTable',
      });
    case 'numberOfQuestions':
      return t('{questionCount} questions', {
        _key: 'numberOfQuestions',
        _context: 'EvidenceTable',
        ...params,
      });
    case 'uploadEvidenceAboutCategory':
      return t('Upload {evidenceType} about {categoryName}', {
        _key: 'uploadEvidenceAboutCategory',
        _context: 'EvidenceTable',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const evidenceTableUploadDialogStrings = (
  key: EvidenceTableUploadDialogStringsKeyType,
  params?: { category?: string; evidenceType?: string },
): string => {
  switch (key) {
    case 'attachCategoryType':
      return t('Attach {category} / {evidenceType}', {
        _key: 'attachCategoryType',
        _context: 'EvidenceTableUploadDialog',
        ...params,
      });
    case 'existingDocumentsOfThisType':
      return t('Existing documents of this type', {
        _key: 'existingDocumentsOfThisType',
        _context: 'EvidenceTableUploadDialog',
      });
    case 'noDocumentsOfThisTypeEmptyMsg':
      return t(
        'There are currently no documents of this type uploaded to the platform. They will appear here once uploaded.',
        {
          _key: 'noDocumentsOfThisTypeEmptyMsg',
          _context: 'EvidenceTableUploadDialog',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const filtersStrings = (key: FiltersStringsKeyType): string => {
  switch (key) {
    case 'answers':
      return t('Answers', {
        _key: 'answers',
        _context: 'Filters',
      });
    case 'clearAll':
      return t('Clear all', {
        _key: 'clearAll',
        _context: 'Filters',
      });
    case 'doesntHaveComments':
      return t('Does not have comments', {
        _key: 'doesntHaveComments',
        _context: 'Filters',
      });
    case 'doesntHaveEvidence':
      return t('Does not have evidence', {
        _key: 'doesntHaveEvidence',
        _context: 'Filters',
      });
    case 'doesntHaveNonConformityRecorded':
      return t('Does not have non-conformity recorded', {
        _key: 'doesntHaveNonConformityRecorded',
        _context: 'Filters',
      });
    case 'evidence':
      return t('Evidence', {
        _key: 'evidence',
        _context: 'Filters',
      });
    case 'filterBy':
      return t('Filter by', {
        _key: 'filterBy',
        _context: 'Filters',
      });
    case 'flagged':
      return t('Flagged', {
        _key: 'flagged',
        _context: 'Filters',
      });
    case 'fullyAnswered':
      return t('Fully answered', {
        _key: 'fullyAnswered',
        _context: 'Filters',
      });
    case 'hasBeenFlagged':
      return t('Has been flagged', {
        _key: 'hasBeenFlagged',
        _context: 'Filters',
      });
    case 'hasComments':
      return t('Has comments', {
        _key: 'hasComments',
        _context: 'Filters',
      });
    case 'hasEvidence':
      return t('Has evidence', {
        _key: 'hasEvidence',
        _context: 'Filters',
      });
    case 'hasNonConformityRecorded':
      return t('Has non-conformity recorded', {
        _key: 'hasNonConformityRecorded',
        _context: 'Filters',
      });
    case 'hasNotBeenFlagged':
      return t('Has not been flagged', {
        _key: 'hasNotBeenFlagged',
        _context: 'Filters',
      });
    case 'notAnswered':
      return t('Not answered', {
        _key: 'notAnswered',
        _context: 'Filters',
      });
    case 'partiallyAnswered':
      return t('Partially answered', {
        _key: 'partiallyAnswered',
        _context: 'Filters',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const forgotPasswordPageStrings = (key: ForgotPasswordPageStringsKeyType): string => {
  switch (key) {
    case 'forgotPasswordResetSuccess':
      return t('Instructions to reset your password have been sent to your e-mail.', {
        _key: 'forgotPasswordResetSuccess',
        _context: 'ForgotPasswordPage',
      });
    case 'submit':
      return t('Submit', {
        _key: 'submit',
        _context: 'ForgotPasswordPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const formikDialogStrings = (
  key: FormikDialogStringsKeyType,
  params?: { name?: string },
): string => {
  switch (key) {
    case 'create':
      return t('Create', {
        _key: 'create',
        _context: 'FormikDialog',
      });
    case 'updateFormFail':
      return t('Failed to update {name}.', {
        _key: 'updateFormFail',
        _context: 'FormikDialog',
        ...params,
      });
    case 'updateFormSuccess':
      return t('Successfully updated {name}.', {
        _key: 'updateFormSuccess',
        _context: 'FormikDialog',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const documentPreviewStrings = (key: DocumentPreviewStringsKeyType): string => {
  switch (key) {
    case 'fileTypeCannotBePreviewed':
      return t(
        'This file cannot be displayed in the browser. You can still view it if you download the file.',
        {
          _key: 'fileTypeCannotBePreviewed',
          _context: 'DocumentPreview',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const formikFileUploadFieldStrings = (key: FormikFileUploadFieldStringsKeyType): string => {
  switch (key) {
    case 'fileTooLarge':
      return t('File is too large, please select a file under 100MB', {
        _key: 'fileTooLarge',
        _context: 'FormikFileUploadField',
      });
    case 'fileTypeNotSupported':
      return t('File type not supported', {
        _key: 'fileTypeNotSupported',
        _context: 'FormikFileUploadField',
      });
    case 'selectFile':
      return t('Select file', {
        _key: 'selectFile',
        _context: 'FormikFileUploadField',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const formikInvitationRoleFieldStrings = (
  key: FormikInvitationRoleFieldStringsKeyType,
): string => {
  switch (key) {
    case 'tooltipTitleForFarmEmployee':
      return t('User with Farm Employee role can only create and complete self-assessments.', {
        _key: 'tooltipTitleForFarmEmployee',
        _context: 'FormikInvitationRoleField',
      });
    case 'tooltipTitleForFarmManager':
      return t(
        'User with Farm Manager role has the same access as Owner/Admin, could edit organisation details, invite new users, create and complete self-assessments, etc.',
        {
          _key: 'tooltipTitleForFarmManager',
          _context: 'FormikInvitationRoleField',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const formikRegisterAccountTypeFieldStrings = (
  key: FormikRegisterAccountTypeFieldStringsKeyType,
): string => {
  switch (key) {
    case 'auditorAndCarryOutInspection':
      return t('I’m an auditor and I want to carry out inspection', {
        _key: 'auditorAndCarryOutInspection',
        _context: 'FormikRegisterAccountTypeField',
      });
    case 'farmManagerOrEmployeeAndJoinOrganization':
      return t('I’m a farm manager or employee and want to join inviting farm organisation', {
        _key: 'farmManagerOrEmployeeAndJoinOrganization',
        _context: 'FormikRegisterAccountTypeField',
      });
    case 'farmOwnerAndManageCertification':
      return t('I’m a farm owner and want to manage my farm certification process', {
        _key: 'farmOwnerAndManageCertification',
        _context: 'FormikRegisterAccountTypeField',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const genericDeleteDialogStrings = (
  key: GenericDeleteDialogStringsKeyType,
  params?: { entityName?: string; entityNames?: string; header?: string; name?: string },
): string => {
  switch (key) {
    case 'notPossibleToRecoverItMsg':
      return t(
        '{header} "{entityName}" will be deleted and it will not be possible to recover it.',
        {
          _key: 'notPossibleToRecoverItMsg',
          _context: 'GenericDeleteDialog',
          ...params,
        },
      );
    case 'notPossibleToRecoverItGeneric':
      return t('Following items will be deleted and it will not be possible to recover it.', {
        _key: 'notPossibleToRecoverItGeneric',
        _context: 'GenericDeleteDialog',
      });
    case 'deleteDialogHeaderDescription':
      return t('Delete this {name}?', {
        _key: 'deleteDialogHeaderDescription',
        _context: 'GenericDeleteDialog',
        ...params,
      });
    case 'deleteEntitySuccess':
      return t('{header} "{entityNames}" was successfully deleted!', {
        _key: 'deleteEntitySuccess',
        _context: 'GenericDeleteDialog',
        ...params,
      });
    case 'multipleItems':
      return t('Multiple items', {
        _key: 'multipleItems',
        _context: 'GenericDeleteDialog',
      });
    case 'unknownEntity':
      return t('Unknown entity', {
        _key: 'unknownEntity',
        _context: 'GenericDeleteDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const getCultivationDisplayNameStrings = (
  key: GetCultivationDisplayNameStringsKeyType,
  params?: { cultivationName?: string; plotName?: string; productionTechniqueName?: string },
): string => {
  switch (key) {
    case 'cultivationDisplayName':
      return t('{cultivationName} on {plotName} {productionTechniqueName}', {
        _key: 'cultivationDisplayName',
        _context: 'GetCultivationDisplayName',
        ...params,
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const helpDeskMenuStrings = (key: HelpDeskMenuStringsKeyType): string => {
  switch (key) {
    case 'chatSupport':
      return t('Chat support', {
        _key: 'chatSupport',
        _context: 'HelpDeskMenu',
      });
    case 'helpCenter':
      return t('Help center', {
        _key: 'helpCenter',
        _context: 'HelpDeskMenu',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const helpDocumentsDialogStrings = (key: HelpDocumentsDialogStringsKeyType): string => {
  switch (key) {
    case 'noHelpDocumentToDisplay':
      return t(
        'No help document to display. Try refreshing the page.\nIf the problem persists, contact Agriplace user support.',
        {
          _key: 'noHelpDocumentToDisplay',
          _context: 'HelpDocumentsDialog',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const justificationTextFieldStrings = (
  key: JustificationTextFieldStringsKeyType,
): string => {
  switch (key) {
    case 'addJustification':
      return t('Add justification', {
        _key: 'addJustification',
        _context: 'JustificationTextField',
      });
    case 'justification':
      return t('Justification', {
        _key: 'justification',
        _context: 'JustificationTextField',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const loginPageStrings = (key: LoginPageStringsKeyType): string => {
  switch (key) {
    case 'forgotYourPassword':
      return t('Forgot your password?', {
        _key: 'forgotYourPassword',
        _context: 'LoginPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const mobileCompatibleDataGridStrings = (
  key: MobileCompatibleDataGridStringsKeyType,
): string => {
  switch (key) {
    case 'checkboxSelection':
      return t('Checkbox selection', {
        _key: 'checkboxSelection',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'searchTitle':
      return t('Search title', {
        _key: 'searchTitle',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'columns':
      return t('Columns', {
        _key: 'columns',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'comfortable':
      return t('Comfortable', {
        _key: 'comfortable',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'compact':
      return t('Compact', {
        _key: 'compact',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'contains':
      return t('contains', {
        _key: 'contains',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'density':
      return t('Density', {
        _key: 'density',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'downloadAsCSV':
      return t('Download as CSV', {
        _key: 'downloadAsCSV',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'endsWith':
      return t('ends with', {
        _key: 'endsWith',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'equals':
      return t('equals', {
        _key: 'equals',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'export':
      return t('Export', {
        _key: 'export',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'filterValue':
      return t('Filter value', {
        _key: 'filterValue',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'showHideAll':
      return t('Show/Hide all', {
        _key: 'hideAll',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'hideFilters':
      return t('Hide filters', {
        _key: 'hideFilters',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'isAnyOf':
      return t('is any of', {
        _key: 'isAnyOf',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'isEmpty':
      return t('is empty', {
        _key: 'isEmpty',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'isNotEmpty':
      return t('is not empty', {
        _key: 'isNotEmpty',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'operator':
      return t('Operator', {
        _key: 'operator',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'print':
      return t('Print', {
        _key: 'print',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'rowSelected':
      return t('row selected', {
        _key: 'rowSelected',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'rowsPerPage':
      return t('Rows per page:', {
        _key: 'rowsPerPage',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'showFilters':
      return t('Show filters', {
        _key: 'showFilters',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'startsWith':
      return t('starts with', {
        _key: 'startsWith',
        _context: 'MobileCompatibleDataGrid',
      });
    case 'value':
      return t('Value', {
        _key: 'value',
        _context: 'MobileCompatibleDataGrid',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const newCommentFormStrings = (key: NewCommentFormStringsKeyType): string => {
  switch (key) {
    case 'addNewComment':
      return t('Add new comment', {
        _key: 'addNewComment',
        _context: 'NewCommentForm',
      });
    case 'addNewCommentFail':
      return t('Failed to add comment.', {
        _key: 'addNewCommentFail',
        _context: 'NewCommentForm',
      });
    case 'addNewCommentSuccess':
      return t('Successfully added comment.', {
        _key: 'addNewCommentSuccess',
        _context: 'NewCommentForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const newCorrectiveActionFormStrings = (
  key: NewCorrectiveActionFormStringsKeyType,
): string => {
  switch (key) {
    case 'addCorrectiveActions':
      return t('Add corrective actions', {
        _key: 'addCorrectiveActions',
        _context: 'NewCorrectiveActionForm',
      });
    case 'addCorrectiveActionFail':
      return t('Failed to add a corrective action.', {
        _key: 'addCorrectiveActionFail',
        _context: 'NewCorrectiveActionForm',
      });
    case 'addCorrectiveActionSuccess':
      return t('Successfully added a corrective action.', {
        _key: 'addCorrectiveActionSuccess',
        _context: 'NewCorrectiveActionForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const newNonConformityFormStrings = (key: NewNonConformityFormStringsKeyType): string => {
  switch (key) {
    case 'addNonConformityFail':
      return t('Failed to add a non-conformity.', {
        _key: 'addNonConformityFail',
        _context: 'NewNonConformityForm',
      });
    case 'addNonConformitySuccess':
      return t('Successfully added a non-conformity.', {
        _key: 'addNonConformitySuccess',
        _context: 'NewNonConformityForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const nonConformityStrings = (key: NonConformityStringsKeyType): string => {
  switch (key) {
    case 'deleteNonConformity':
      return t('Delete non-conformity', {
        _key: 'deleteNonConformity',
        _context: 'NonConformity',
      });
    case 'deleteNonConformityFail':
      return t('Failed to delete non-conformity.', {
        _key: 'deleteNonConformityFail',
        _context: 'NonConformity',
      });
    case 'deleteNonConformitySuccess':
      return t('Successfully deleted non-conformity.', {
        _key: 'deleteNonConformitySuccess',
        _context: 'NonConformity',
      });
    case 'nonConformity':
      return t('Non-conformity', {
        _key: 'nonConformity',
        _context: 'NonConformity',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationClientIdentifierFormLeafStrings = (
  key: OrganizationClientIdentifierFormLeafStringsKeyType,
): string => {
  switch (key) {
    case 'certificationBody':
      return t('Certification Body', {
        _key: 'certificationBody',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'certificationBodyName':
      return t('Certification Body Name', {
        _key: 'certificationBodyName',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'charityMembership':
      return t('LEAF Charity membership', {
        _key: 'charityMembership',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'charityMembershipNumber':
      return t('LEAF Charity Membership Number *', {
        _key: 'charityMembershipNumber',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'charityMembershipType':
      return t('LEAF Charity Membership Type *', {
        _key: 'charityMembershipType',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'countryParishHoldingNumber':
      return t('County Parish Holding ( CPH ) Number', {
        _key: 'countryParishHoldingNumber',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'countryParishHoldingNumberInfo':
      return t('Applicable only for growers in England', {
        _key: 'countryParishHoldingNumberInfo',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'farmAssuranceScheme':
      return t('Farm Assurance Scheme', {
        _key: 'farmAssuranceScheme',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'farmAssuranceNumber':
      return t('Farm Assurance Number', {
        _key: 'farmAssuranceNumber',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'marqueEquivalenceScheme':
      return t('LEAF Marque Equivalence Scheme', {
        _key: 'marqueEquivalenceScheme',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'marqueEquivalenceSchemeInfo':
      return t('Applicable only if you are participating in an Equivalence Scheme (ex. SIZA)', {
        _key: 'marqueEquivalenceSchemeInfo',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'marqueEquivalenceSchemeNumber':
      return t('LEAF Marque Equivalence Scheme Number', {
        _key: 'marqueEquivalenceSchemeNumber',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'marqueMembership':
      return t('LEAF Marque membership', {
        _key: 'marqueMembership',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'marqueMembershipDesc':
      return t(
        'Applicable only for LEAF Marque members. If you wish to become LEAF Marque certified please contact an approved',
        {
          _key: 'marqueMembershipDesc',
          _context: 'OrganizationClientIdentifierFormLeaf',
        },
      );
    case 'marqueMembershipNumber':
      return t('LEAF Marque Membership Number', {
        _key: 'marqueMembershipNumber',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    case 'otherMemberships':
      return t('Other memberships', {
        _key: 'otherMemberships',
        _context: 'OrganizationClientIdentifierFormLeaf',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationClientIdentifiersStrings = (
  key: OrganizationClientIdentifiersStringsKeyType,
): string => {
  switch (key) {
    case 'addClientIdentifierButton':
      return t('Add another certification company', {
        _key: 'addClientIdentifierButton',
        _context: 'OrganizationClientIdentifiers',
      });
    case 'applicableCertifications':
      return t('Applicable certifications', {
        _key: 'applicableCertifications',
        _context: 'OrganizationClientIdentifiers',
      });
    case 'clientIdAlreadyExists':
      return t('Client ID already exists', {
        _key: 'clientIdAlreadyExists',
        _context: 'OrganizationClientIdentifiers',
      });
    case 'clientIdentifiersDescription':
      return t(
        'Adding ID number(s) is required to start a self assessment. You can add them here or at the self assessment setup step later. All data will be synced.',
        {
          _key: 'clientIdentifiersDescription',
          _context: 'OrganizationClientIdentifiers',
        },
      );
    case 'ggn':
      return t('GGN (GlobalGap Number)', {
        _key: 'ggn',
        _context: 'OrganizationClientIdentifiers',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationCultivationTableStrings = (
  key: OrganizationCultivationTableStringsKeyType,
): string => {
  switch (key) {
    case 'cultivationTableNoRowsMsgForAuditors':
      return t('Add cultivations here and easily reuse them across your inspections.', {
        _key: 'cultivationTableNoRowsMsgForAuditors',
        _context: 'OrganizationCultivationTable',
      });
    case 'cultivationTableNoRowsMsgForFarmManagers':
      return t('Add cultivations here and easily reuse them across your self assessments.', {
        _key: 'cultivationTableNoRowsMsgForFarmManagers',
        _context: 'OrganizationCultivationTable',
      });
    case 'plot':
      return t('Plot', {
        _key: 'plot',
        _context: 'OrganizationCultivationTable',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationDetailsFormStrings = (
  key: OrganizationDetailsFormStringsKeyType,
): string => {
  switch (key) {
    case 'addingIdentifierIsRequired':
      return t(
        'Adding identifier(s) is required to start a self assessment. You could add it here or at the self assessment setup step later. All data will be synced.',
        {
          _key: 'addingIdentifierIsRequired',
          _context: 'OrganizationDetailsForm',
        },
      );
    case 'changeYourPlan':
      return t('Change your plan', {
        _key: 'changeYourPlan',
        _context: 'OrganizationDetailsForm',
      });
    case 'invalidPhoneNumber':
      return t('Invalid phone number', {
        _key: 'invalidPhoneNumber',
        _context: 'OrganizationDetailsForm',
      });
    case 'leafInfoMsg':
      return t(
        'In case of any incorrect information above please contact LEAF membership team at',
        {
          _key: 'leafInfoMsg',
          _context: 'OrganizationDetailsForm',
        },
      );
    case 'organizationLogo':
      return t('Organisation logo', {
        _key: 'organizationLogo',
        _context: 'OrganizationDetailsForm',
      });
    case 'organizationName':
      return t('Organisation name', {
        _key: 'organizationName',
        _context: 'OrganizationDetailsForm',
      });
    case 'phoneNumber':
      return t('Phone number', {
        _key: 'phoneNumber',
        _context: 'OrganizationDetailsForm',
      });
    case 'primaryOrganizationContactEmail':
      return t('Primary organisation contact e-mail', {
        _key: 'primaryOrganizationContactEmail',
        _context: 'OrganizationDetailsForm',
      });
    case 'stateProvinceRegionCounty':
      return t('State / Province / Region / County', {
        _key: 'stateProvinceRegionCounty',
        _context: 'OrganizationDetailsForm',
      });
    case 'streetLine1':
      return t('Street (Line 1)', {
        _key: 'streetLine1',
        _context: 'OrganizationDetailsForm',
      });
    case 'streetLine2':
      return t('Street (Line 2)', {
        _key: 'streetLine2',
        _context: 'OrganizationDetailsForm',
      });
    case 'yourOrganizationIsCurrentlyOn':
      return t('Your organization is currently on', {
        _key: 'yourOrganizationIsCurrentlyOn',
        _context: 'OrganizationDetailsForm',
      });
    case 'website':
      return t('Website', {
        _key: 'website',
        _context: 'OrganizationDetailsForm',
      });
    case 'websiteShouldBeValidUrl':
      return t('Website should be a valid URL', {
        _key: 'websiteShouldBeValidUrl',
        _context: 'OrganizationDetailsForm',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationInvitationEditDialogStrings = (
  key: OrganizationInvitationEditDialogStringsKeyType,
  params?: { email?: string },
): string => {
  switch (key) {
    case 'createInviteSuccessMessage':
      return t('Successfully created invitation for {email}.', {
        _key: 'createInviteSuccessMessage',
        _context: 'OrganizationInvitationEditDialog',
        ...params,
      });
    case 'sendInvite':
      return t('Send invite', {
        _key: 'sendInvite',
        _context: 'OrganizationInvitationEditDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationInvitationTableStrings = (
  key: OrganizationInvitationTableStringsKeyType,
): string => {
  switch (key) {
    case 'addUserToOrganization':
      return t('Add user to organisation', {
        _key: 'addUserToOrganization',
        _context: 'OrganizationInvitationTable',
      });
    case 'invitation':
      return t('Invitation', {
        _key: 'invitation',
        _context: 'OrganizationInvitationTable',
      });
    case 'inviteUserToOrganization':
      return t('Invite user to organisation', {
        _key: 'inviteUserToOrganization',
        _context: 'OrganizationInvitationTable',
      });
    case 'organizationInvitationTableNoRowsMsg':
      return t(
        'Invite your coworkers to the platform.\nOnce they have accepted the invitation they will appear on the user list below.',
        {
          _key: 'organizationInvitationTableNoRowsMsg',
          _context: 'OrganizationInvitationTable',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationPageStrings = (key: OrganizationPageStringsKeyType): string => {
  switch (key) {
    case 'users':
      return t('Users', {
        _key: 'users',
        _context: 'OrganizationPage',
      });
    case 'idNumbers':
      return t('ID Numbers', {
        _key: 'idNumbers',
        _context: 'OrganizationPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const organizationUserTableStrings = (key: OrganizationUserTableStringsKeyType): string => {
  switch (key) {
    case 'activeUsers':
      return t('Active users', {
        _key: 'activeUsers',
        _context: 'OrganizationUserTable',
      });
    case 'colleagues':
      return t('Colleagues', {
        _key: 'colleagues',
        _context: 'OrganizationUserTable',
      });
    case 'user':
      return t('User', {
        _key: 'user',
        _context: 'OrganizationUserTable',
      });
    case 'userTableNoRowsMsg':
      return t(
        'No active users to display. Try refreshing the page.\nIf the problem persists, contact Agriplace user support.',
        {
          _key: 'userTableNoRowsMsg',
          _context: 'OrganizationUserTable',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const pendingIncomingRequestDialogStrings = (
  key: PendingIncomingRequestDialogStringsKeyType,
): string => {
  switch (key) {
    case 'allowAccess':
      return t('Allow access', {
        _key: 'allowAccess',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'denyAccess':
      return t('Deny access', {
        _key: 'denyAccess',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'failedToApproveRequest':
      return t('Failed to approve request.', {
        _key: 'failedToApproveRequest',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'failedToDenyRequest':
      return t('Failed to deny request.', {
        _key: 'failedToDenyRequest',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'incomingRequestDesc':
      return t(
        'is requesting access to your self assessment. Select the assessment you want to share from the list below:',
        {
          _key: 'incomingRequestDesc',
          _context: 'PendingIncomingRequestDialog',
        },
      );
    case 'newIncomingRequest':
      return t('New incoming request', {
        _key: 'newIncomingRequest',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'openAssessments':
      return t('Open assessments', {
        _key: 'openAssessments',
        _context: 'PendingIncomingRequestDialog',
      });
    case 'requestRejected':
      return t('Request rejected.', {
        _key: 'requestRejected',
        _context: 'PendingIncomingRequestDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const resetPasswordPageStrings = (key: ResetPasswordPageStringsKeyType): string => {
  switch (key) {
    case 'resetCode':
      return t('Reset code', {
        _key: 'resetCode',
        _context: 'ResetPasswordPage',
      });
    case 'resetMyPassword':
      return t('Reset my password', {
        _key: 'resetMyPassword',
        _context: 'ResetPasswordPage',
      });
    case 'resetPasswordSuccess':
      return t('Successfully reset password! You can now log in.', {
        _key: 'resetPasswordSuccess',
        _context: 'ResetPasswordPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const routesHeaderStrings = (key: RoutesHeaderStringsKeyType): React.JSX.Element => {
  switch (key) {
    case 'assessmentAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      return (
        <T
          _context="settings,AssessmentArchivePage"
          _key="assessment"
          _str="Assessment"
        />
      );
    case 'createOrganization':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="settings"
          _key="createOrganization"
          _str="Create organisation"
        />
      );
    case 'myAccount':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="settings"
          _key="myAccount"
          _str="My account"
        />
      );
    case 'organizationProfileAsComponent':
      // Because of Transifex small t function refresh problem.
      // It should be duplicated.
      return (
        <T
          _context="settings"
          _key="organizationProfile"
          _str="Organisation profile"
        />
      );
    case 'resetYourPassword':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="settings"
          _key="resetYourPassword"
          _str="Reset your password"
        />
      );
    case 'signUpVerification':
      // Because of Transifex small t function refresh problem.
      return (
        <T
          _context="settings"
          _key="signUpVerification"
          _str="Sign up verification"
        />
      );
    default:
      return (
        <T
          _key="translationNotFound"
          _str="Translation not found!"
        />
      );
  }
};

export const selectionListStrings = (key: SelectionListStringsKeyType): string => {
  switch (key) {
    case 'noCultivationsAdded':
      return t('No cultivations added', {
        _key: 'noCultivationsAdded',
        _context: 'SelectionList',
      });
    case 'none':
      return t('None', {
        _key: 'none',
        _context: 'SelectionList',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const signUpFormStrings = (key: SignUpFormStringsKeyType): string => {
  switch (key) {
    case 'and':
      return t('and', {
        _key: 'and',
        _context: 'SignUpForm',
      });
    case 'country':
      return t('Country', {
        _key: 'country',
        _context: 'SignUpForm',
      });
    case 'planToUsePlatform':
      return t('How do you plan to use the platform?', {
        _key: 'planToUsePlatform',
        _context: 'SignUpForm',
      });
    case 'privacyPolicy':
      return t('Privacy Policy', {
        _key: 'privacyPolicy',
        _context: 'SignUpForm',
      });
    case 'repeatPassword':
      return t('Repeat password', {
        _key: 'repeatPassword',
        _context: 'SignUpForm',
      });
    case 'termsAndConditions':
      return t('The terms and conditions must be accepted.', {
        _key: 'termsAndConditions',
        _context: 'SignUpForm',
      });
    case 'termsOfService':
      return t('Terms of Service', {
        _key: 'termsOfService',
        _context: 'SignUpForm',
      });
    case 'understandAndAcceptPolicy':
      return t('I understand and accept the', {
        _key: 'understandAndAcceptPolicy',
        _context: 'SignUpForm',
      });
    case 'understandAndAcceptPolicyAgriplace':
      return t(
        'I agree to my personal data being processed as necessary for providing the service to me.',
        {
          _key: 'understandAndAcceptPolicyAgriplace',
          _context: 'SignUpForm',
        },
      );
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const signUpVerificationPageStrings = (
  key: SignUpVerificationPageStringsKeyType,
): string => {
  switch (key) {
    case 'confirmationCodeResent':
      return t('Confirmation code resent.', {
        _key: 'confirmationCodeResent',
        _context: 'SignUpVerificationPage',
      });
    case 'resendVerificationCode':
      return t('Resend verification code', {
        _key: 'resendVerificationCode',
        _context: 'SignUpVerificationPage',
      });
    case 'yourAccountSuccessfullyVerified':
      return t('Your account has been successfully verified.', {
        _key: 'yourAccountSuccessfullyVerified',
        _context: 'SignUpVerificationPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const splashPageStrings = (key: SplashPageStringsKeyType): string => {
  switch (key) {
    case 'addWebAppToHomeScreen':
      return t('Add Agriplace Farm web app to your mobile home screen', {
        _key: 'addWebAppToHomeScreen',
        _context: 'SplashPage',
      });
    case 'androidStep1':
      return t('1. Click on the', {
        _key: 'androidStep1',
        _context: 'SplashPage',
      });
    case 'androidStep2':
      return t('2. Select Add to Home Screen', {
        _key: 'androidStep2',
        _context: 'SplashPage',
      });
    case 'androidStep3':
      return t('3. Click on the Add button.', {
        _key: 'androidStep3',
        _context: 'SplashPage',
      });
    case 'button':
      return t('button', {
        _key: 'button',
        _context: 'SplashPage',
      });
    case 'goToLoginPage':
      return t('Go to login page', {
        _key: 'goToLoginPage',
        _context: 'SplashPage',
      });
    case 'iosStep1':
      return t('1. Click on the share button below', {
        _key: 'iosStep1',
        _context: 'SplashPage',
      });
    case 'iosStep2':
      return t('2. Scroll down and select Add to Home Screen', {
        _key: 'iosStep2',
        _context: 'SplashPage',
      });
    case 'iosStep3':
      return t('3. Click on the', {
        _key: 'iosStep3',
        _context: 'SplashPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const stepperPageStrings = (key: StepperPageStringsKeyType): string => {
  switch (key) {
    case 'couldNotFindThatTask':
      return t('Could not find that task...', {
        _key: 'couldNotFindThatTask',
        _context: 'StepperPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const stepperFinishDialogStrings = (key: StepperFinishDialogStringsKeyType): string => {
  switch (key) {
    case 'madeReadyForAuditSuccess':
      return t('Your assessment has been successfully made ready for audit!', {
        _key: 'madeReadyForAuditSuccess',
        _context: 'StepperFinishDialog',
      });
    case 'madeReadyForConfirmSuccess':
      return t('Your assessment has been successfully made ready for confirm!', {
        _key: 'madeReadyForConfirmSuccess',
        _context: 'StepperFinishDialog',
      });
    case 'madeReadyForConfirmedSuccess':
      return t('The result of this assessment has been successfully confirmed!', {
        _key: 'madeReadyForConfirmedSuccess',
        _context: 'StepperFinishDialog',
      });
    case 'madeReadyForReviewSuccess':
      return t('Your assessment has been successfully made ready for review!', {
        _key: 'madeReadyForReviewSuccess',
        _context: 'StepperFinishDialog',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const tabbedPageStrings = (key: TabbedPageStringsKeyType): string => {
  switch (key) {
    case 'completeOrganizationSettings':
      return t('Complete organisation settings', {
        _key: 'completeOrganizationSettings',
        _context: 'TabbedPage',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};

export const assessmentKebabMenuStrings = (key: AssessmentKebabMenuStringsKeyType): string => {
  switch (key) {
    case 'farmOrganizationDetails':
      return t('Farm organisation details', {
        _key: 'farmOrganizationDetails',
        _context: 'AssessmentKebabMenu',
      });
    default:
      return t('Translation not found!', {
        _key: 'translationNotFound',
      });
  }
};
