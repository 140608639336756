import { skipToken } from '@reduxjs/toolkit/query';
import { useTenantId } from 'src/hooks';
import {
  useGetAssessmentControlPointEvidenceListQuery,
  useGetControlPointCriticalityListQuery,
  useGetControlPointQuery,
} from 'src/services/farmApi';

export const useGetEvidenceControlPointDetail = (
  controlPointId: string | undefined,
  assessmentId: string | undefined,
  evidenceId: string | undefined,
) => {
  const skip = !controlPointId || !assessmentId;
  const tid = useTenantId();

  const {
    controlPointCode,
    controlPointCriteria,
    controlPointDisplayCode,
    criticalityId,
    mainText,
    smallLogo,
    isLoading: isControlPointLoading,
    isFetching: isControlPointFetching,
  } = useGetControlPointQuery(skip ? skipToken : controlPointId, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      controlPointCode: data?.code,
      controlPointCriteria: data?.criteria,
      controlPointDisplayCode: data?.display_code,
      criticalityId: data?.criticality_id,
      mainText: data?.main_text,
      smallLogo: data?.logo,
      isLoading,
      isFetching,
    }),
  });

  const {
    evidenceLinkId,
    isLoading: isAssessmentControlPointEvidenceListLoading,
    isFetching: isAssessmentControlPointEvidenceListFetching,
  } = useGetAssessmentControlPointEvidenceListQuery(
    skip || !tid
      ? skipToken
      : {
          tid,
          assessment_id: assessmentId ? [assessmentId] : undefined,
          control_point_id: controlPointId ? [controlPointId] : undefined,
        },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => {
        const evidenceLink = (data || []).find(
          (assessmentControlPointEvidence) =>
            assessmentControlPointEvidence.evidence_id === evidenceId &&
            assessmentControlPointEvidence.control_point_id === controlPointId,
        );
        return {
          evidenceLinkId: evidenceLink?.uuid,
          isLoading,
          isFetching,
        };
      },
    },
  );

  const {
    data: controlPointCriticalityList,
    isLoading: isControlPointCriticalityListLoading,
    isFetching: isControlPointCriticalityListFetching,
  } = useGetControlPointCriticalityListQuery({});
  return {
    isLoading:
      isControlPointLoading ||
      isAssessmentControlPointEvidenceListLoading ||
      isControlPointCriticalityListLoading,
    isFetching:
      isControlPointFetching ||
      isAssessmentControlPointEvidenceListFetching ||
      isControlPointCriticalityListFetching,
    controlPointCode,
    controlPointCriteria,
    controlPointCriticalityList,
    controlPointDisplayCode,
    criticalityId,
    evidenceLinkId,
    mainText,
    smallLogo,
  };
};
