import { InfoRounded } from '@mui/icons-material';
import { Box, MenuItem, Skeleton, TextField } from '@mui/material';
import { type ReactNode } from 'react';
import { MobileCompatibleTooltip } from 'src/components/common';
import { appColors } from 'src/theme';
import { type LabelValuePair } from 'src/types/LabelValuePair';

import { type FormikTextFieldProps } from '../FormikTextField';

interface FormikSelectFieldProps extends FormikTextFieldProps {
  options: LabelValuePair[];
  disableAutoFocusItem?: boolean;
  dividerIndex?: number;
  isHideInputLabel?: boolean;
  tooltipTitle?: ReactNode;
}

/**
 * This component mates a Formik instance (created via the useFormik hook) with a MUI (selectable)
 * TextField.
 */
export const FormikSelectField = ({
  dividerIndex,
  formik,
  id,
  label,
  options,
  tooltipTitle,
  disabled = false,
  disableAutoFocusItem = false,
  isHideInputLabel = false,
  isLoading = false,
  placeholder = '',
  ...restProps
}: FormikSelectFieldProps) => {
  let labelOpacity = 1;
  if (isHideInputLabel) {
    if (!formik?.values[id]) {
      labelOpacity = 0.54;
    } else {
      labelOpacity = 0;
    }
  }
  return isLoading ? (
    <Box
      sx={{
        border: 1,
        borderColor: appColors.border,
        borderRadius: '4px',
        px: '14px',
        py: '15px',
        width: '100%',
      }}
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <TextField
      disabled={isLoading || disabled}
      error={formik?.touched[id] && Boolean(formik.errors[id])}
      fullWidth
      helperText={formik?.touched[id] && (formik.errors[id] as string)}
      id={id}
      label={label}
      name={id}
      onBlur={formik?.handleBlur}
      onChange={formik?.handleChange}
      placeholder={typeof label === 'string' ? placeholder || label : placeholder}
      select
      slotProps={{
        input: {
          endAdornment: tooltipTitle ? (
            <MobileCompatibleTooltip
              sx={{ mr: 3 }}
              title={tooltipTitle}
            >
              <InfoRounded color="secondary" />
            </MobileCompatibleTooltip>
          ) : null,
        },
        select: { MenuProps: { disableAutoFocusItem } },
        inputLabel: { shrink: isHideInputLabel ? false : undefined },
      }}
      sx={{
        '& .MuiInputLabel-root': {
          opacity: labelOpacity,
        },
      }}
      value={formik?.values[id]}
      {...restProps}
    >
      {options.map((option, index) => (
        <MenuItem
          key={`${option.value}_${index}`}
          sx={
            index === dividerIndex ? { borderBottom: 2, borderBottomColor: 'divider' } : undefined
          }
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
