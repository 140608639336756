import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTE_PATH_DASHBOARD, ROUTES } from 'src/settings';

import { LoadingArea } from '../common';

export const AppRoutes = () => (
  <Suspense fallback={<LoadingArea />}>
    <Routes>
      {ROUTES.map((route) => (
        <Route
          key={route.path}
          element={route.element}
          path={route.path}
        />
      ))}
      <Route
        element={
          <Navigate
            replace
            to={ROUTE_PATH_DASHBOARD}
          />
        }
        path="/"
      />
    </Routes>
  </Suspense>
);
