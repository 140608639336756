import { format } from 'date-fns';
import {
  type ShortcomingCorrectiveActionCreateInput,
  type ShortcomingCorrectiveActionCreateOutput,
  type ShortcomingCorrectiveActionDetailOutput,
  type ShortcomingCorrectiveActionListOutputItem,
  type ShortcomingCorrectiveActionsListParams,
  type ShortcomingCorrectiveActionUpdateInput,
  type ShortcomingCorrectiveActionUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  injectQueryParams,
  transformObject,
  type UpdateParamsWithTenantId,
} from 'src/services/farmApi/helpers';
import { DATE_SERVER_FORMAT } from 'src/settings';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'shortcoming-corrective-actions';

export type TransformedNonConformityAndCorrectiveActionListOutputItem = Omit<
  ShortcomingCorrectiveActionListOutputItem,
  'created_at' | 'modified_at' | 'reported_date'
> & {
  created_at: Date;
  modified_at: Date;
  reported_date: Date;
};

type TransformedNonConformityAndCorrectiveActionDetailOutput = Omit<
  ShortcomingCorrectiveActionDetailOutput,
  'created_at' | 'modified_at' | 'reported_date'
> & {
  created_at: Date;
  modified_at: Date;
  reported_date: Date;
};

const responseTransformations = {
  created_at: (value: string): Date => new Date(value),
  modified_at: (value: string): Date => new Date(value),
  reported_date: (value: string): Date => new Date(value),
};

const requestTransformations = {
  created_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
  modified_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
  reported_date: (value: Date): string => format(value, DATE_SERVER_FORMAT),
};

const nonConformityAndCorrectiveActionsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNonConformityAndCorrectiveActionList: build.query<
      TransformedNonConformityAndCorrectiveActionListOutputItem[],
      ShortcomingCorrectiveActionsListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: () => [{ type: TagType.NonConformityAndCorrectiveAction, id: 'LIST' }],
      transformResponse: (data: ShortcomingCorrectiveActionListOutputItem[]) =>
        data.map((item) => transformObject(item, responseTransformations)),
    }),

    getNonConformityAndCorrectiveAction: build.query<
      TransformedNonConformityAndCorrectiveActionDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: (result, _error, _params) => [
        { type: TagType.NonConformityAndCorrectiveAction, id: result?.uuid },
      ],
      transformResponse: (data: ShortcomingCorrectiveActionDetailOutput) =>
        transformObject(data, responseTransformations),
    }),

    createNonConformityAndCorrectiveAction: build.mutation<
      ShortcomingCorrectiveActionCreateOutput,
      CreateParamsWithTenantId<ShortcomingCorrectiveActionCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body: transformObject(body, requestTransformations),
      }),
      invalidatesTags: () => [{ type: TagType.NonConformityAndCorrectiveAction, id: 'LIST' }],
    }),

    deleteNonConformityAndCorrectiveAction: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: TagType.NonConformityAndCorrectiveAction, id: 'LIST' },
        { type: TagType.NonConformityAndCorrectiveAction, id: params.id },
      ],
    }),

    updateNonConformityAndCorrectiveAction: build.mutation<
      ShortcomingCorrectiveActionUpdateOutput,
      UpdateParamsWithTenantId<ShortcomingCorrectiveActionUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: TagType.NonConformityAndCorrectiveAction, id: 'LIST' },
        { type: TagType.NonConformityAndCorrectiveAction, id: params.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateNonConformityAndCorrectiveActionMutation,
  useGetNonConformityAndCorrectiveActionListQuery,
  useGetNonConformityAndCorrectiveActionQuery,
  useDeleteNonConformityAndCorrectiveActionMutation,
  useUpdateNonConformityAndCorrectiveActionMutation,
} = nonConformityAndCorrectiveActionsEndpoints;
