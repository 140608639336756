import { Box, Skeleton, type StandardTextFieldProps, TextField } from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik';
import { appColors } from 'src/theme';

export type FormikTextFieldProps = Partial<StandardTextFieldProps> & {
  id: string;
  formik?: FormikProps<FormikValues>;
  isLoading?: boolean;
};

export const FormikTextField = ({
  formik,
  id,
  label,
  disabled = false,
  isLoading = false,
  placeholder = '',
  ...restProps
}: FormikTextFieldProps) =>
  isLoading || !formik ? (
    <Box
      sx={{
        border: 1,
        borderColor: appColors.border,
        borderRadius: '4px',
        px: '14px',
        py: '15px',
        width: '100%',
        ...restProps?.sx,
      }}
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <TextField
      disabled={isLoading || disabled}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      fullWidth
      helperText={formik.touched[id] && (formik.errors[id] as string)}
      id={id}
      label={label}
      name={id}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      placeholder={typeof label === 'string' ? placeholder || label : placeholder}
      type="text"
      value={formik.values[id]}
      {...restProps}
    />
  );
