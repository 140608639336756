import { type SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { type FormikValues } from 'formik';
import qs from 'qs';
import { type DynamicObjectType } from 'src/global';
import { commonStrings } from 'src/languages/en-UK';

// SEE: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
  typeof error === 'object' && error != null && 'status' in error;

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
const isErrorWithMessage = (error: unknown): error is { message: string } =>
  typeof error === 'object' &&
  error != null &&
  'message' in error &&
  typeof error.message === 'string';

/**
 * Extract error message text from RTK errors for use with snackbar
 */
export const extractErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  if (isFetchBaseQueryError(error)) {
    return 'error' in error ? error.error : JSON.stringify(error.data);
  }
  if (isErrorWithMessage(error)) {
    return error.message;
  }
  return commonStrings('unknownErrorOccurred');
};

/**
 * Stringify query params and add them to a url (including the all-important "?")
 */
export const injectQueryParams = (url: string, params: DynamicObjectType, skipNulls = false) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = params;
  const queryString = qs.stringify(rest, { indices: false, skipNulls });
  if (queryString.length) {
    return `${url}?${queryString}`;
  }
  return url;
};

/**
 * Converts Formik values to FormData. Django doesn't accept file uploads without FormData, so when
 * a form includes a file upload, this util can convert the data to a body which Django parses.
 * @param values
 */
export const formikToFormData = (
  values: FormikValues,
  shouldConvertArrayToStringify = false,
): FormData => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value?.length > 0) {
        if (shouldConvertArrayToStringify) {
          formData.append(key, JSON.stringify(value));
        } else {
          value.forEach((i) => formData.append(key, i));
        }
      }
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

/**
 * Performs request or response transformations for RTKQuery.
 * @param data
 * @param transformations
 * @returns
 */
export const transformObject = <T, O extends Object>(
  data: T | undefined,
  // Since it is dynamic, it must be any type.
  transformations: { [key: string]: (value: any) => any },
): O => {
  const newObject: DynamicObjectType = {};
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (transformations[key]) {
        newObject[key] = transformations[key](value);
      } else {
        newObject[key] = value;
      }
    });
  }

  return newObject as O;
};

export const toUpperCaseFirstChar = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export type DetailParams = {
  id: string;
};

export type DetailParamsGeneric<T> = DetailParams & Omit<T, 'uuid'>;

export type DetailParamsWithTenantId = DetailParams & {
  tid: string;
};

export type CreateParamsWithTenantId<T> = {
  tid: string;
  body: T | undefined;
};

export type DeleteParamsWithTenantId = DetailParams & {
  parentResourceId?: string; // Used for invalidating tags, as delete endpoints don't return data
  tid: string;
  id: string;
};

export interface BulkUpdateParamsWithTenantId<T> {
  tid: string;
  body: T;
}

/**
 * This prevents the need to redefine this type pattern within RTK Query endpoint definitions.
 */
export interface UpdateParams<T> {
  id: string;
  body: T;
}

/**
 * This prevents the need to redefine this type pattern within RTK Query endpoint definitions.
 */
export type UpdateParamsWithTenantId<T> = UpdateParams<T> & {
  tid: string;
};
