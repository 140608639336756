import { Box, Skeleton, useTheme } from '@mui/material';

interface SkeletonMultiLineTextProps {
  variant: 'body1' | 'body2';
  lines?: number;
  paddingBottom?: number;
  paddingTop?: number;
}

export const SkeletonMultiLineText = ({
  variant,
  lines = 2,
  paddingBottom = 0,
  paddingTop = 0,
}: SkeletonMultiLineTextProps): React.JSX.Element => {
  const theme = useTheme();
  const { lineHeight, fontSize } = theme.typography[variant];

  const convertFontSizeToPx = (): number => {
    if (typeof fontSize === 'number') {
      return fontSize;
    }
    if (typeof fontSize === 'string') {
      return parseInt(fontSize, 10);
    }
    return 0;
  };

  const convertLineHeightToPx = () => {
    if (typeof lineHeight === 'string') {
      return parseInt(lineHeight, 10);
    }
    if (typeof lineHeight === 'number') {
      return convertFontSizeToPx() * lineHeight;
    }
    return 0;
  };

  const skeletonHeight = Math.round(convertFontSizeToPx());
  const skeletonMargin = convertLineHeightToPx() - skeletonHeight;

  const renderLines = () => {
    const skeletons = [];
    for (let i = 0; i < lines; i += 1) {
      const isLast = i === lines - 1;
      skeletons.push(
        <Skeleton
          key={`${i}_skeleton`}
          height={skeletonHeight}
          sx={{
            mt: `${skeletonMargin / 2 + paddingTop * 8}px`,
            mb: `${skeletonMargin / 2 + paddingBottom * 8}px`,
          }}
          variant="rounded"
          width={isLast ? '35%' : '100%'}
        />,
      );
    }
    return skeletons;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderLines()}
    </Box>
  );
};
