import { skipToken } from '@reduxjs/toolkit/query';
import { useContext } from 'react';
import { TenantContext } from 'src/components/TenantProvider';
import { useGetAssessmentUserPermissionsQuery } from 'src/services/farmApi';

export const useGetAssessmentUserPermission = (assessmentId: string | undefined) => {
  const { tenantId } = useContext(TenantContext);

  const { data: permissions } = useGetAssessmentUserPermissionsQuery(
    tenantId && assessmentId ? { id: assessmentId, tid: tenantId } : skipToken,
  );

  return permissions;
};
