import { skipToken } from '@reduxjs/toolkit/query';
import { useContext } from 'react';
import { TenantContext } from 'src/components/TenantProvider';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetOrganizationQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useCurrentOrganization = (isSkip = false) => {
  const { organizationId, tenantId, activeMembership } = useContext(TenantContext);
  const { authStatus } = useUserAuth();
  const skip =
    !activeMembership?.organization_id ||
    !organizationId ||
    !tenantId ||
    authStatus !== AuthStatus.LoggedIn ||
    isSkip;

  return useGetOrganizationQuery(skip ? skipToken : { id: organizationId, tid: tenantId });
};
