import { HelpOutlineRounded, HelpRounded, SupportAgentRounded } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import isFunction from 'lodash.isfunction';
import { helpDeskMenuStrings } from 'src/languages/en-UK';
import { ZOHO_SERVER_URL } from 'src/settings';

import { DropDownMenu } from '../DropDownMenu';

export const HelpDeskMenu = () => (
  <DropDownMenu
    anchorContent={<HelpOutlineRounded fontSize="medium" />}
    anchorProps={{
      variant: 'text',
      sx: { color: 'action.active', minWidth: 'unset', mr: 2 },
    }}
  >
    <MenuItem
      onClick={() => {
        if (isFunction(window.zE)) {
          window.zE('messenger', 'open');
        }
      }}
    >
      <ListItemIcon>
        <SupportAgentRounded
          color="action"
          fontSize="medium"
        />
      </ListItemIcon>
      <ListItemText>{helpDeskMenuStrings('chatSupport')}</ListItemText>
    </MenuItem>
    <MenuItem
      component="a"
      href={ZOHO_SERVER_URL}
      rel="noreferrer"
      target="_blank"
    >
      <ListItemIcon>
        <HelpRounded
          color="action"
          fontSize="medium"
        />
      </ListItemIcon>
      <ListItemText>{helpDeskMenuStrings('helpCenter')}</ListItemText>
    </MenuItem>
  </DropDownMenu>
);
