import { type DynamicObjectType } from 'src/global';
import { useCurrentOrganization } from 'src/hooks';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';

const subdomainOriginMap: DynamicObjectType = {
  planetproof: SignUpOriginEnum.SMK,
  'myleaf-self-assessment': SignUpOriginEnum.LEAF,
};

export const useGetUserOrigin = (): SignUpOriginEnum => {
  const { data: organization } = useCurrentOrganization();
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const lowestLevelSubdomain = window.location.hostname.split('.').shift() ?? '';

  return (
    organization?.origin ??
    subdomainOriginMap[lowestLevelSubdomain] ??
    searchParams.get('origin') ??
    SignUpOriginEnum.AGRIPLACE
  );
};

// This hook exists here to centralize the logic for determining if the user is coming from the LEAF platform,
// as this is currently used in multiple places to enable/disable certain features for LEAF users.
// Note: this is a temporary solution to be used during the LEAF development phase, but does not replace code that generally
// checks for the origin of the user and changes behavior based on that, e.g. the logo or color scheme.
// TODOJack: This hook should be removed once the LEAF development is wrapping up and we have permanent solutions in place.
export const useIsLeafOrigin = (): boolean => {
  const origin = useGetUserOrigin();

  return origin === SignUpOriginEnum.LEAF;
};
