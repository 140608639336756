import { type SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';

import { type TabbedPageTabDef } from './TabbedPage.types';

type UseTabsReturnValue = [number, (_e: SyntheticEvent, i: number) => void];

type SearchParamsType = {
  tab: string;
  task?: string;
  origin?: string;
  email?: string;
  accounttype?: string;
  redirect?: string;
};
export const useTabs = (tabs: (TabbedPageTabDef | null)[]): UseTabsReturnValue => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');
  const currentTask = searchParams.get('task');
  const redirectPath = searchParams.get('redirect');
  const prefilledEmail = searchParams.get('email');
  const accountType = searchParams.get('accounttype');
  const origin = useGetUserOrigin();
  const targetTab = tabs.find((tabDef) => tabDef?.slug === currentTab);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const getCurrentSearchParams = useCallback(
    (tabSlug: string, isNavigate = false) => {
      let navigateSearchParams = `?tab=${tabSlug}`;
      let newSearchParams: SearchParamsType = {
        tab: tabSlug,
      };
      if (currentTask) {
        newSearchParams = { task: currentTask, ...newSearchParams };
        navigateSearchParams += `&task=${currentTask}`;
      }
      if (origin) {
        newSearchParams = { ...newSearchParams, origin };
        navigateSearchParams += `&origin=${origin}`;
      }
      if (prefilledEmail) {
        newSearchParams = { ...newSearchParams, email: encodeURIComponent(prefilledEmail) };
        navigateSearchParams += `&email=${encodeURIComponent(prefilledEmail)}`;
      }
      if (accountType) {
        newSearchParams = { ...newSearchParams, accounttype: accountType };
        navigateSearchParams += `&accounttype=${accountType}`;
      }
      if (redirectPath) {
        newSearchParams = { ...newSearchParams, redirect: redirectPath };
        navigateSearchParams += `&redirect=${redirectPath}`;
      }
      return isNavigate ? navigateSearchParams : newSearchParams;
    },
    [accountType, currentTask, origin, prefilledEmail, redirectPath],
  );

  useEffect((): void => {
    if (tabs?.length > 0 && targetTab) {
      const targetTabIndex = tabs.indexOf(targetTab);
      if (activeTabIndex !== targetTabIndex) {
        setActiveTabIndex(targetTabIndex);
      }
    }
  }, [activeTabIndex, tabs, targetTab]);

  const handleChangeTab = (_e: SyntheticEvent, i: number) => {
    setActiveTabIndex(i);
    if (tabs?.[i]?.slug) {
      const search = getCurrentSearchParams(tabs[i].slug, true) as string;
      navigate(
        {
          search,
          pathname: location.pathname,
        },
        { replace: true },
      );
    }
  };

  return [activeTabIndex, handleChangeTab];
};
