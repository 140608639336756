import {
  type AssessmentAgriplaceCriticalityListOutputItem,
  type AssessmentBaseModuleOutputItem,
  type AssessmentCategoryEvidenceTypeListOutputItem,
  type AssessmentCategoryEvidenceTypeStatusOutput,
  type AssessmentConfirmedInput,
  type AssessmentConfirmedOutput,
  type AssessmentControlPointBreadcrumbsListOutputItem,
  type AssessmentControlPointCultivationListOutputItem,
  type AssessmentCreateInput,
  type AssessmentCreateOutput,
  type AssessmentCriticalityProgressIndicationOutput,
  type AssessmentDetailOutput,
  type AssessmentListOutputItem,
  type AssessmentModuleSidebarOutputItem,
  type AssessmentsGetAssessmentControlPointCultivationsListParams,
  type AssessmentsGetCategoryEvidenceTypesListParams,
  type AssessmentsGetCategoryEvidenceTypeStatusRetrieveParams,
  type AssessmentsGetCriticalityCategoryEvidenceTypesListParams,
  type AssessmentsListParams,
  type AssessmentToBeAuditedOutput,
  type AssessmentToBeConfirmedOutput,
  type AssessmentToBeReviewedOutput,
  type AssessmentUpdateAuditDurationInput,
  type AssessmentUpdateInput,
  type AssessmentUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import { baseApi } from 'src/services/farmApi/baseApi';
import { TagType } from 'src/services/farmApi/TagType';

import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';

const RESOURCE = 'assessments';

export type Breadcrumb = {
  breadcrumb: string;
  smallLogoId: string;
};

const assessmentEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentList: build.query<AssessmentListOutputItem[], AssessmentsListParams>({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [TagType.Assessment, TagType.UserProfile],
    }),

    getAssessment: build.query<AssessmentDetailOutput, DetailParamsWithTenantId>({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/?include_child_modules=false`,
      providesTags: [TagType.Assessment],
    }),

    getAssessmentAgriplaceCriticalityList: build.query<
      AssessmentAgriplaceCriticalityListOutputItem[],
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/get-agriplace-criticalities`,
        method: 'GET',
      }),
      providesTags: [TagType.Assessment, TagType.UserProfile],
    }),

    getAssessmentCriticalityCategoryEvidenceTypeList: build.query<
      AssessmentCategoryEvidenceTypeListOutputItem[],
      AssessmentsGetCriticalityCategoryEvidenceTypesListParams
    >({
      query: ({ id, tid, ...restParams }) =>
        injectQueryParams(
          `/tid/${tid}/${RESOURCE}/${id}/get-criticality-category-evidence-types/`,
          restParams,
          true,
        ),
      providesTags: [TagType.Assessment, TagType.UserProfile],
    }),

    getAssessmentCriticalityProgressIndication: build.query<
      AssessmentCriticalityProgressIndicationOutput[],
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/get-criticality-process-indication/`,
        method: 'GET',
      }),
      providesTags: (_response, _error, params) => [
        TagType.Assessment,
        TagType.UserProfile,
        { type: TagType.AssessmentControlPointEvidence, id: params.id },
      ],
    }),

    getAssessmentCategoryEvidenceTypeStatus: build.query<
      AssessmentCategoryEvidenceTypeStatusOutput,
      AssessmentsGetCategoryEvidenceTypeStatusRetrieveParams
    >({
      query: ({ id, tid, ...restParams }) =>
        injectQueryParams(
          `/tid/${tid}/${RESOURCE}/${id}/get-category-evidence-type-status/`,
          restParams,
          true,
        ),
      providesTags: (response) => [
        TagType.Assessment,
        TagType.UserProfile,
        ...(response?.evidence_types
          ? response.evidence_types.map((item) => ({
              type: TagType.AssessmentControlPointEvidence,
              evidence_type_id: item.uuid,
            }))
          : []),
      ],
    }),

    getAssessmentCategoryEvidenceTypeList: build.query<
      AssessmentCategoryEvidenceTypeListOutputItem[],
      AssessmentsGetCategoryEvidenceTypesListParams
    >({
      query: ({ tid, id, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/${id}/get-category-evidence-types/`, restParams),
      providesTags: (response, _error, params) => [
        { type: TagType.AssessmentCategoryEvidenceType, id: params.id },
        { type: TagType.Assessment, id: 'LIST' },
        ...(response
          ? response.map((item) => ({
              type: TagType.AssessmentCategoryEvidenceType,
              id: item.uuid,
            }))
          : []),
      ],
    }),

    getAssessmentControlPointBreadcrumbsList: build.query<
      Record<string, Breadcrumb>,
      DetailParamsWithTenantId
    >({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/get-control-point-breadcrumbs/`,
      transformResponse: (response: AssessmentControlPointBreadcrumbsListOutputItem[]) =>
        response.reduce(
          (acc, item) => {
            acc[item.control_point_id] = {
              breadcrumb: item.breadcrumb,
              smallLogoId: item.small_logo_id,
            };
            return acc;
          },
          {} as Record<string, Breadcrumb>,
        ),
      providesTags: [
        TagType.Assessment,
        TagType.UserProfile,
        TagType.AssessmentControlPointBreadcrumbs,
      ],
    }),

    createAssessment: build.mutation<
      AssessmentCreateOutput,
      CreateParamsWithTenantId<AssessmentCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    deleteAssessment: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    updateAssessment: build.mutation<
      AssessmentUpdateOutput,
      UpdateParamsWithTenantId<AssessmentUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        TagType.Assessment,
        TagType.AssessmentControlPointBreadcrumbs,
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointCultivations,
        TagType.AssessmentControlPointList,
        TagType.AssessmentControlPoint,
      ],
    }),

    updateAssessmentAuditDuration: build.mutation<
      null,
      UpdateParamsWithTenantId<AssessmentUpdateAuditDurationInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/audit-duration/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    getControlPointCultivations: build.query<
      AssessmentControlPointCultivationListOutputItem[],
      AssessmentsGetAssessmentControlPointCultivationsListParams
    >({
      query: ({ uuid, tid, ...restParams }) =>
        injectQueryParams(
          `/tid/${tid}/${RESOURCE}/${uuid}/get-assessment-control-point-cultivations/`,
          restParams,
          true,
        ),
      providesTags: [
        TagType.Assessment,
        TagType.UserProfile,
        TagType.AssessmentControlPointCultivations,
      ],
    }),

    getModuleSidebarList: build.query<
      AssessmentModuleSidebarOutputItem[],
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/get-module-sidebar/`,
        method: 'GET',
      }),
      providesTags: [TagType.Assessment, TagType.AssessmentModuleSidebar, TagType.UserProfile],
      transformResponse: (data: AssessmentModuleSidebarOutputItem[]) =>
        data.toSorted((a, b) => a.level - b.level),
    }),

    updateAssessmentToBeAudited: build.mutation<
      AssessmentToBeAuditedOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/to-be-audited/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    updateAssessmentToBeReviewed: build.mutation<
      AssessmentToBeReviewedOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/to-be-reviewed/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    updateAssessmentToBeConfirmed: build.mutation<
      AssessmentToBeConfirmedOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/to-be-confirmed/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    updateAssessmentConfirmed: build.mutation<
      AssessmentConfirmedOutput,
      UpdateParamsWithTenantId<AssessmentConfirmedInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/confirmed/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.Assessment],
    }),

    getAssessmentBaseModules: build.query<
      AssessmentBaseModuleOutputItem[],
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/get-assessment-base-modules`,
        method: 'GET',
      }),
      providesTags: [TagType.Assessment],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
  useGetAssessmentAgriplaceCriticalityListQuery,
  useGetAssessmentCategoryEvidenceTypeStatusQuery,
  useGetAssessmentCategoryEvidenceTypeListQuery,
  useGetAssessmentCriticalityCategoryEvidenceTypeListQuery,
  useGetAssessmentCriticalityProgressIndicationQuery,
  useGetAssessmentListQuery,
  useGetAssessmentQuery,
  useUpdateAssessmentMutation,
  useUpdateAssessmentAuditDurationMutation,
  useGetAssessmentControlPointBreadcrumbsListQuery,
  useGetControlPointCultivationsQuery,
  useGetModuleSidebarListQuery,
  useUpdateAssessmentToBeAuditedMutation,
  useUpdateAssessmentToBeReviewedMutation,
  useUpdateAssessmentToBeConfirmedMutation,
  useUpdateAssessmentConfirmedMutation,
  useGetAssessmentBaseModulesQuery,
} = assessmentEndpoints;
