import { Button, type ButtonProps, Menu, type MenuProps } from '@mui/material';
import {
  type ComponentType,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  useState,
} from 'react';

interface DropDownMenuProps {
  anchorContent: ReactElement;
  anchorProps: ButtonProps;
  children: ReactNode;
  menuProps?: Omit<MenuProps, 'open'>;
  // Since it is dynamic, it must be any type.
  Anchor?: ComponentType<any>;
  onOpen?: () => void;
  hideMenuOnItemClick?: boolean;
}

export const DropDownMenu = ({
  anchorContent,
  anchorProps,
  children,
  Anchor = Button,
  onOpen,
  menuProps = {},
  hideMenuOnItemClick = false,
}: DropDownMenuProps): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onCloseMenu = () => {
    setAnchorEl(null);
  };
  const onOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };
  return (
    <>
      <Anchor
        onClick={onOpenMenu}
        {...anchorProps}
      >
        {anchorContent}
      </Anchor>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={(event) => {
          event.stopPropagation();
          if (hideMenuOnItemClick) {
            onCloseMenu();
          }
        }}
        onClose={onCloseMenu}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...menuProps}
      >
        {children}
      </Menu>
    </>
  );
};
