import { type PayloadAction } from '@reduxjs/toolkit';
import { STANDARD_SELECTION_GLOBAL } from 'src/components/AssessmentConfigMatrix/AssessmentConfigMatrix.utils';

import { type AssessmentConfigState, type MatrixSelection } from '../types';
import { doesSelectionExist } from '../utils/doesSelectionExist';

/**
 * Handles global (all-organization) checkbox changes in the matrix.
 *
 * @param selection - Selection object containing the standard ID and cultivation ID
 * @param isChecked - Whether this change is an addition or deletion to the selection set
 */
export const changeStandardSelection = (
  state: AssessmentConfigState,
  action: PayloadAction<{
    isChecked: boolean;
    selectedRows: (string | undefined)[];
    selection: MatrixSelection;
  }>,
): AssessmentConfigState => {
  const { isChecked, selectedRows, selection } = action.payload;

  /**
   * Creates a sub-selection to add or remove. Global checkboxes are not actually present in the
   * selection set (see comment at beginning of hook definition), so a sub-selection for each of
   * the checkboxes below the global checkbox must be created. Only the row ID is provided because
   * the column ID will always be the same as the global checkbox's column ID.
   *
   * @param rowId - Row ID (cultivation ID) for the sub-selection
   * @returns - A MatrixSelection for a cultivation under the global checkbox
   */
  const createSubSelection = (rowId: string): MatrixSelection => ({
    colId: selection.colId,
    rowId,
  });

  if (isChecked) {
    // If adding selections, store them in a temporary array
    const selectionsToAdd = [];
    if (state.rows.length === 0) {
      selectionsToAdd.push(selection);
    } else {
      selectedRows.forEach((row: string) => {
        // Add any required sub-selections to the "to-add" array
        const subSelection = createSubSelection(row);
        if (!doesSelectionExist(state.selections, subSelection)) {
          selectionsToAdd.push(subSelection);
        }
      });
    }

    return {
      ...state,
      selections: [...state.selections, ...selectionsToAdd],
    };
  }

  // Otherwise, when removing selections, store them in a temporary array
  const selectionsToRemove: MatrixSelection[] = [];

  if (state.rows.length <= 0) {
    // Because this is a global change, iterate over each row (cultivation)
    state.selections.forEach((existingSelection) => {
      if (existingSelection.rowId === STANDARD_SELECTION_GLOBAL) {
        const subSelection = createSubSelection(STANDARD_SELECTION_GLOBAL);
        if (doesSelectionExist(state.selections, subSelection)) {
          selectionsToRemove.push(subSelection);
        }
      }
    });
  } else {
    state.rows.forEach((row) => {
      if (row.id) {
        // Add any required sub-selections to the "to-remove" array
        const subSelection = createSubSelection(row.id);
        if (doesSelectionExist(state.selections, subSelection)) {
          selectionsToRemove.push(subSelection);
        }
      }
    });
  }

  // Finally, remove them
  return {
    ...state,
    selections: state.selections.filter(
      (a) => !selectionsToRemove.find((b) => a.colId === b.colId && a.rowId === b.rowId),
    ),
  };
};
